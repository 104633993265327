<template>
  <ul class="list-group">
    <li
      class="list-group-item fs-md-15 fw-medium"
      v-for="(label, index) in labels"
      :key="index"
    >
      <input
        class="form-check-input me-1"
        type="checkbox"
        :id="`checkbox-${index}`"
        :value="index"
        :checked="!hiddenSeries.includes(index)"
        @change="toggleVisibility(index)"
      />
      <label class="form-check-label" :for="`checkbox-${index}`">{{ label }}</label>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SeriesVisibilityControl",
  props: {
    labels: {
      type: Array,
      required: true,
    },
    hiddenSeries: {
      type: Array,
      required: true,
    },
  },
  emits: ['toggle-visibility'],
  setup(props, { emit }) {
    const toggleVisibility = (index: number) => {
      emit('toggle-visibility', index);
    };

    return {
      toggleVisibility,
    };
  },
});
</script>

<style scoped>
.list-group-item {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 10px;
}
</style>
