<template>
  <BreadCrumb PageTitle="Suggested System Analysis" />
  <div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div class="col-lg-8 d-flex">
          <div class="card mb-25 border-0 rounded-0 bg-white flex-grow-1">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <SuggestedSystemPieChart :tableName="tableName" :hiddenSeries="hiddenSeries" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex">
          <div class="card mb-25 border-0 rounded-0 bg-white flex-grow-1">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <span class="d-block mb-6 fs-20 text-uppercase fw-bold text-dark-emphasis">
                Control Series Visibility
              </span>
              <SeriesVisibilityControl :labels="chartLabels" :hiddenSeries="hiddenSeries" @toggle-visibility="toggleVisibility" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <SuggestedSystemTable :tableName="tableName" :hiddenSeries="hiddenSeries" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import BreadCrumb from "@/components/Common/DynamicBreadCrumb.vue";
import SuggestedSystemPieChart from "@/components/ELSA/SuggestedSystemAnalysis/SuggestedSystemPieChart.vue";
import SuggestedSystemTable from "@/components/ELSA/SuggestedSystemAnalysis/SuggestedSystemTable.vue";
import SeriesVisibilityControl from "@/components/ELSA/SuggestedSystemAnalysis/SeriesVisibilityControl.vue";
import { provideHiddenSeries } from "@/components/ELSA/SuggestedSystemAnalysis/useHiddenSeries";
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "SuggestedSystemAnalysisPage",
  components: {
    BreadCrumb,
    SuggestedSystemPieChart,
    SuggestedSystemTable,
    SeriesVisibilityControl,
  },
  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chartLabels = ref<string[]>([]);
    const hiddenSeries = provideHiddenSeries();

    const toggleVisibility = (index: number) => {
      if (hiddenSeries.value.includes(index)) {
        hiddenSeries.value = hiddenSeries.value.filter(i => i !== index);
      } else {
        hiddenSeries.value.push(index);
      }
      console.log(`Toggled visibility for index ${index}:`, hiddenSeries.value);
    };

    // Fetch the labels on mount or from the chart component
    onMounted(async () => {
      try {
        const response = await fetch(
          `${BASE_HSUITE_URL}/api/elsa/GetSuggestedSystemResults/${props.tableName}`
        );
        const data = await response.json();
        chartLabels.value = data.map((item: any) => item.SuggestedSystem);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    return {
      chartLabels,
      hiddenSeries,
      toggleVisibility,
    };
  },
});
</script>

<style scoped>
.card {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.letter-spacing {
  letter-spacing: 0.5px;
}

.h-100 {
  height: 100%;
}
</style>