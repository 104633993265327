<template>
    <div>
      <div class="row mb-4">
        <div class="col-md-6">
          <label for="startDate">Start Date</label>
          <input
            type="date"
            id="startDate"
            class="form-control"
            v-model="startDate"
            @change="emitDateChange"
          />
        </div>
        <div class="col-md-6">
          <label for="endDate">End Date</label>
          <input
            type="date"
            id="endDate"
            class="form-control"
            v-model="endDate"
            @change="emitDateChange"
          />
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-3 col-sm-6" v-for="(stat, index) in stats" :key="index">
          <div class="card mb-25 border-0 rounded-0 bg-white stats-box">
            <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
              <div class="d-flex align-items-center">
                <div :class="['icon position-relative rounded-circle text-center', stat.class]">
                  <i :class="stat.icon"></i>
                </div>
                <div class="title ms-15">
                  <span class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis">
                    {{ stat.title }}
                  </span>
                  <h4 class="fw-black mb-8 lh-1">
                    {{ stat.sign }}{{ formattedValue(stat.value, stat.format) }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div v-if="loading" class="loading-overlay">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, watch, onMounted } from "vue";
  import axios from "axios";
  import { BASE_HSUITE_URL } from '@/core/constant/env';

  export default defineComponent({
    name: "OrderDataStatsGrid",
    props: {
      tableName: {
        type: String,
        required: true,
      },
    },
    emits: ["dateChange"],
    setup(props, { emit }) {
      const stats = ref([
        {
          title: "Total Unique Orders",
          value: 0,
          class: "bg-primary",
          icon: "ph ph-shopping-cart",
          sign: "",
          format: "integer",
        },
        {
          title: "Total Order Lines",
          value: 0,
          class: "bg-success",
          icon: "ph ph-list",
          sign: "",
          format: "integer",
        },
        {
          title: "Total Unique SKUs",
          value: 0,
          class: "bg-warning",
          icon: "ph ph-barcode",
          sign: "",
          format: "integer",
        },
        {
          title: "Average Lines Per Order",
          value: 0,
          class: "bg-info",
          icon: "ph ph-chart-line-up",
          sign: "",
          format: "2dp",
        },
        {
          title: "Average Orders Per Day",
          value: 0,
          class: "bg-danger",
          icon: "ph ph-calendar-check",
          sign: "",
          format: "0dp",
        },
        {
          title: "Average Lines Per Day",
          value: 0,
          class: "bg-secondary",
          icon: "ph ph-calendar-check",
          sign: "",
          format: "0dp",
        },
        {
          title: "Max Orders Per Day",
          value: 0,
          class: "bg-info",
          icon: "ph ph-calendar-check",
          sign: "",
          format: "integer",
        },
        {
          title: "Max Lines Per Day",
          value: 0,
          class: "bg-warning",
          icon: "ph ph-calendar-check",
          sign: "",
          format: "integer",
        }
      ]);
  
      const startDate = ref("");
      const endDate = ref("");
      const loading = ref(false);
  
      onMounted(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        
        startDate.value = "2023-01-01";
        endDate.value = `${yyyy}-${mm}-${dd}`;
  
        fetchStats();
      });
  
      const fetchStats = async () => {
        if (startDate.value && endDate.value) {
          const url = `${BASE_HSUITE_URL}/api/OrderDataAnalysis/GetSummaryData?tableName=${props.tableName}&startDate=${startDate.value}T00:00:00&endDate=${endDate.value}T23:59:59`;
  
          try {
            loading.value = true;
            const response = await axios.get(url);
            if (response.data.status === "Success") {
              const data = response.data.data;
              stats.value[0].value = data.totalUniqueOrders;
              stats.value[1].value = data.totalOrderLines;
              stats.value[2].value = data.totalUniqueSKUs;
              stats.value[3].value = data.averageLinesPerOrder;
              stats.value[4].value = data.averageOrdersPerDay;
              stats.value[5].value = data.averageLinesPerDay;
              stats.value[6].value = data.maxOrdersPerDay;
              stats.value[7].value = data.maxLinesPerDay;
            }
          } catch (error) {
            console.error("Error fetching stats:", error);
          } finally {
            loading.value = false;
          }
        }
      };
  
      const formattedValue = (value, format) => {
        if (format === "integer") {
          return value.toLocaleString();
        } else if (format === "2dp") {
          return value.toFixed(2).toLocaleString();
        } else if (format === "0dp") {
          return Math.round(value).toLocaleString();
        } else {
          return value;
        }
      };
  
      const emitDateChange = () => {
        console.log("Emitting date change:", { startDate: startDate.value, endDate: endDate.value });
        emit("dateChange", { startDate: startDate.value, endDate: endDate.value });
      };
  
      watch([startDate, endDate], fetchStats);
  
      return {
        stats,
        startDate,
        endDate,
        loading,
        fetchStats,
        formattedValue,
        emitDateChange,
      };
    },
  });
  </script>
  
  <style scoped>
  .stats-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
  
  .title {
    margin-left: 15px;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading {
    cursor: wait;
  }
  </style>
  