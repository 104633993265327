<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10"> UserName </label>
            <input type="text" class="form-control shadow-none rounded-0 text-black" :value="user.userName" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10"> Email </label>
            <input type="email" class="form-control shadow-none rounded-0 text-black" :value="user.email" disabled>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">Status</label>
            <div v-for="role in roles" :key="role.id" class="form-check fs-md-15 fs-lg-16 text-black mb-0 d-inline-block me-15 me-md-25">
              <input class="form-check-input shadow-none" type="checkbox" :id="role.id"  :value="role.id" v-model="selectedRoles" />
              <label class="form-check-label"> {{ role.name }} </label>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <button @click="SaveData" class="default-btn transition border-0 fw-medium text-white 
            pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
            fs-md-15 fs-lg-16 m-5 bg-success" type="button"> Save </button>
            <button @click="GoToMainMenu" class="default-btn transition border-0 fw-medium text-white 
            pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
            fs-md-15 fs-lg-16 m-5 bg-danger" type="button"> Cancel </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</template>
<script>
import { GetAllRoles, FindUserWithRolesById, AssignRolesToUser } from '@/core/services/entities/authentication.service'
import { useToast } from 'vue-toastification'
export default {
  name: "UpdateUserRole",
  data() {
    return {
      user: {}, 
      roles: [], 
      selectedRoles: [], 
      formData: {},
    };
  },
  methods: {
    async GoToMainMenu() {
				this.$router.push({ name: "UsersListPage" });
    },
    async InitUserById(id) {
      let userWithRoles = await FindUserWithRolesById(id);
      if(userWithRoles)
      {
        this.user = userWithRoles.user;
        this.selectedRoles = userWithRoles.roleIds;
      }
    },
    async InitRoles() {
      this.roles = await GetAllRoles();
    },
    async SaveData() {
      const toast = useToast()
      var response = await AssignRolesToUser({userId: this.user.id, roleIds: this.selectedRoles});
      if(response.status == 200 || response.status == 201)
      {
        toast.success('User roles has been updated.', {
          timeout: 2000
        })
        this.GoToMainMenu();
        
      }
      
    }
  },
  created() {
    this.InitUserById(this.$route.params.id);
    this.InitRoles();
  }
}
</script>