<template>
    <div>
      <BreadCrumb PageTitle="Order Data Statistics" />
      <div class="row">
        <div class="col-lg-12">
          <OrderDataStatsGrid :tableName="tableName" :startDate="startDate" :endDate="endDate" @dateChange="handleDateChange" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <OrderDataBarChart :startDate="startDate" :endDate="endDate" :tableName="tableName" />
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, watch } from "vue";
  import { useRoute } from "vue-router";
  import BreadCrumb from "@/components/Common/DynamicBreadCrumb.vue";
  import OrderDataStatsGrid from "@/components/ELSA/OrderData/OrderDataStatsGrid.vue";
  import OrderDataBarChart from "@/components/ELSA/OrderData/OrderDataBarChart.vue";
  
  export default defineComponent({
    name: "OrderDataStatsPage",
    components: {
      BreadCrumb,
      OrderDataStatsGrid,
      OrderDataBarChart,
    },
    setup() {
      const route = useRoute();
      const tableName = ref<string | null>(null);
      const startDate = ref("");
      const endDate = ref("");
  
      const setDefaultDates = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        
        startDate.value = "2023-01-01";
        endDate.value = `${yyyy}-${mm}-${dd}`;
        console.log("Default dates set:", startDate.value, endDate.value);
      };
  
      const handleDateChange = (newDates) => {
        startDate.value = newDates.startDate;
        endDate.value = newDates.endDate;
        console.log("Dates updated in OrderDataStatsPage:", startDate.value, endDate.value);
      };
  
      onMounted(() => {
        const tableNameParam = route.params.tableName;
        if (typeof tableNameParam === "string") {
          tableName.value = tableNameParam;
        } else if (Array.isArray(tableNameParam)) {
          tableName.value = tableNameParam[0];
        }
        console.log("Table name set:", tableName.value);
        setDefaultDates();
      });
  
      watch([startDate, endDate], () => {
        console.log("Dates changed in OrderDataStatsPage:", startDate.value, endDate.value);
      });
  
      return {
        tableName,
        startDate,
        endDate,
        handleDateChange,
      };
    },
  });
  </script>
  
  <style scoped>
  /* Add any specific styles for OrderDataStatsPage here */
  </style>
  