<template>
    <div class="lightbox">
      <div class="lightbox-content">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "LightboxWrapper",
  };
  </script>
  
  <style scoped>
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  </style>
  