<template>
  <div class="row">
    <div class="col-12">
      <LeadsInformation
        :enquiryID="projectDetails.enquiryID"
        :clientName="projectDetails.clientName"
        :status="projectDetails.status"
        :salesman="projectDetails.salesman"
        :businessArea="projectDetails.businessArea"
        :projectDescription="projectDetails.projectDescription"
        :projectValue="projectDetails.projectValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType, watch } from "vue";
import axios from "axios";
import LeadsInformation from "./ProjectInformation.vue";
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "ProjectSummary",
  components: {
    LeadsInformation,
  },
  props: {
    leadID: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const projectDetails = ref({
      enquiryID: 0,
      clientName: '',
      status: '',
      salesman: '',
      businessArea: '',
      projectDescription: '',
      projectValue: 0
    });

    const fetchProjectDetails = async (leadID: number) => {
      try {
        const response = await axios.get(`${BASE_HSUITE_URL}/api/Project/GetProjectByLeadID/${leadID}`);
        if (response.data.status === "Success") {
          projectDetails.value = {
            enquiryID: response.data.projectDetails.enquiryID,
            clientName: response.data.projectDetails.clientName,
            status: response.data.projectDetails.status,
            salesman: response.data.projectDetails.salesman,
            businessArea: response.data.projectDetails.businessArea,
            projectDescription: response.data.projectDetails.projectDescription,
            projectValue: response.data.projectDetails.projectValue
          };
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    onMounted(() => {
      if (props.leadID) {
        fetchProjectDetails(props.leadID);
      }
    });

    watch(() => props.leadID, (newLeadID) => {
      if (newLeadID) {
        fetchProjectDetails(newLeadID);
      }
    });

    return {
      projectDetails,
    };
  },
});
</script>
