<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-20 p-sm-25 p-md-30 p-lg-35 letter-spacing">
      <div class="d-flex justify-content-between mb-10">
        <span class="fs-16 text-uppercase fw-medium text-dark-emphasis">
          Suggested System Data
        </span>
        <div class="dropdown">
          <button
            class="dropdown-toggle card-dot-btn lh-1 bg-transparent border-0 shadow-none p-0 transition"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="flaticon-dots"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end" ref="dropdownMenu">
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="exportTable('jpg')"
              >
                <i
                  class="flaticon-download lh-1 me-8 position-relative top-1"
                ></i>
                Export as JPG
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="exportTable('png')"
              >
                <i
                  class="flaticon-download lh-1 me-8 position-relative top-1"
                ></i>
                Export as PNG
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <div class="btn-group" role="group" aria-label="Value Toggle">
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="{ active: displayMode === 'values' }"
            @click="setDisplayMode('values')"
          >
            Values
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="{ active: displayMode === 'percentages' }"
            @click="setDisplayMode('percentages')"
          >
            Percentages
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="{ active: displayMode === 'both' }"
            @click="setDisplayMode('both')"
          >
            Both
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover" id="suggestedSystemTable">
          <thead>
            <tr>
              <th>Suggested System</th>
              <th>SKU Quantity</th>
              <th>Volume In Stock</th>
              <th>Volume Per Day</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredTableData" :key="index">
              <td>
                <span :style="{ backgroundColor: getColor(item.originalIndex) }" class="legend-dot"></span>
                {{ item.SuggestedSystem }}
              </td>
              <td>{{ formatData(item.SKUQuantity, item.SKUPercentage) }}</td>
              <td>{{ formatData(item.VolumeInStock, item.VolumeInStockPercentage) }}</td>
              <td>{{ formatData(item.VolumePerDay, item.VolumePerDayPercentage) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

#### Script:

```ts
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import html2canvas from "html2canvas";
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "SuggestedSystemTable",
  props: {
    tableName: {
      type: String,
      required: true
    },
    hiddenSeries: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const tableData = ref<any[]>([]);
    const filteredTableData = ref<any[]>([]);
    const colors = ["#06B48A", "#6560F0", "#6FD3F7", "#F7B924", "#F7464A", "#8E44AD"];
    const displayMode = ref('values');
    const dropdownMenu = ref<HTMLElement | null>(null);

    const formatNumber = (num: number) => num.toFixed(1);
    
    const formatPercentage = (num: number) => `${(num * 100).toFixed(1)}%`;

    const getColor = (index: number) => colors[index % colors.length];

    const setDisplayMode = (mode: string) => {
      displayMode.value = mode;
    };

    const formatData = (value: number, percentage: number) => {
      switch (displayMode.value) {
        case 'values':
          return formatNumber(value);
        case 'percentages':
          return formatPercentage(percentage);
        case 'both':
          return `${formatNumber(value)} (${formatPercentage(percentage)})`;
      }
    };

    const filterTableData = () => {
      console.log('Filtering table data based on hiddenSeries:', props.hiddenSeries);
      filteredTableData.value = tableData.value
        .map((item, index) => ({ ...item, originalIndex: index }))
        .filter((item) => !props.hiddenSeries.includes(item.originalIndex));
      console.log('Filtered table data:', filteredTableData.value);
    };

    const exportTable = async (format: string) => {
      const tableElement = document.getElementById('suggestedSystemTable');
      if (!tableElement) return;

      if (dropdownMenu.value) {
        dropdownMenu.value.style.display = 'none';
      }

      const canvas = await html2canvas(tableElement, {
        backgroundColor: format === 'jpg' ? '#FFFFFF' : null
      });

      if (dropdownMenu.value) {
        dropdownMenu.value.style.display = '';
      }

      const dataURL = canvas.toDataURL(`image/${format}`);
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `SuggestedSystemTable.${format}`;
      link.click();
    };

    onMounted(async () => {
      try {
        const response = await fetch(`${BASE_HSUITE_URL}/api/elsa/GetSuggestedSystemResults/${props.tableName}`);
        const data = await response.json();

        const totalSKUQuantity = data.reduce((acc: number, item: any) => acc + item.SKUQuantity, 0);
        const totalVolumeInStock = data.reduce((acc: number, item: any) => acc + item.VolumeInStock, 0);
        const totalVolumePerDay = data.reduce((acc: number, item: any) => acc + item.VolumePerDay, 0);

        tableData.value = data.map((item: any, index: number) => ({
          ...item,
          SKUPercentage: item.SKUQuantity / totalSKUQuantity,
          VolumeInStockPercentage: item.VolumeInStock / totalVolumeInStock,
          VolumePerDayPercentage: item.VolumePerDay / totalVolumePerDay,
          originalIndex: index
        }));

        filterTableData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    watch(
      () => props.hiddenSeries,
      (newVal) => {
        console.log('hiddenSeries changed:', newVal);
        filterTableData();
      },
      { deep: true }
    );

    return {
      tableData,
      filteredTableData,
      formatNumber,
      formatPercentage,
      getColor,
      displayMode,
      setDisplayMode,
      formatData,
      exportTable,
      dropdownMenu,
    };
  },
});
</script>

#### Style:

```css
<style scoped>
.card-body {
  font-size: 18px; /* Increased font size for all text in card */
}

.table th,
.table td {
  font-size: 18px; /* Increased font size for table text */
}

.table-responsive {
  overflow-x: auto;
}

.legend-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.btn-group .btn {
  margin-right: 0.5rem;
}

.btn-group .btn.active {
  background-color: #0069d9;
  color: white;
}

.dropdown-toggle.card-dot-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  min-width: 150px;
}

.dropdown-item i {
  margin-right: 10px;
}
</style>
