import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_DataTableList = _resolveComponent("DataTableList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Data Table Overview" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_DataTableList, {
          dataStreamID: _ctx.dataStreamID,
          leadID: _ctx.leadID
        }, null, 8, ["dataStreamID", "leadID"])
      ])
    ])
  ]))
}