<template>
    <div class="card mb-25 border-0 rounded-0 bg-white create-new-project-box">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
        <form @submit.prevent="createWorkOrderRequest">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Request Type
                </label>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ selectedRequestTypeName || 'Select Request Type' }}
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      v-for="type in requestTypes"
                      :key="type.requestTypeID"
                      @click="selectRequestType(type)"
                    >
                      <a class="dropdown-item" href="#">{{ type.requestTypeName }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Project
                </label>
                <input
                  v-model="projectSearchText"
                  @input="searchProjects"
                  type="text"
                  placeholder="Type to search projects"
                  class="form-control"
                />
                <ul class="list-group mt-2">
                  <li
                    v-for="project in filteredProjects"
                    :key="project.enquiryID"
                    @click="selectProject(project)"
                    class="list-group-item fw-medium fs-md-15"
                  >
                    {{ project.clientName }} - {{ project.enquiryID }} - {{ project.projectDescription }}
                  </li>
                </ul>
                <div v-if="selectedProjectName" class="selected-project mt-2">
                  <strong>Selected Project:</strong> {{ selectedProjectName }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Due Date
                </label>
                <input
                  v-model="form.dueDate"
                  type="date"
                  class="form-control shadow-none rounded-0 text-black"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Instructions
                </label>
                <quill-editor
                  v-model="instructions"
                  :options="editorOptions"
                />
              </div>
            </div>
            <div class="col-md-12">
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Create Work Order Request
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { defineComponent, ref, onMounted } from 'vue';
  import { QuillEditor } from '@vueup/vue-quill';
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  
  export default defineComponent({
    name: 'WorkOrderRequestForm',
    components: {
      QuillEditor
    },
    setup() {
      const form = ref({
        requestTypeID: null,
        requesterID: 1,
        creationDate: new Date().toISOString().slice(0, 19),
        dueDate: new Date().toISOString().slice(0, 10),
        status: 'Pending',
        projectID: null,
        instructions: ''
      });
  
      const requestTypes = ref([]);
      const projects = ref([]);
      const filteredProjects = ref([]);
      const projectSearchText = ref('');
      const selectedRequestTypeName = ref('');
      const selectedProjectName = ref('');
      const instructions = ref('');
  
      const editorOptions = {
        theme: 'snow',
        placeholder: 'Enter instructions here...'
      };
  
      const fetchRequestTypes = async () => {
        try {
          const response = await axios.get(`${BASE_HSUITE_URL}/api/RequestType/GetAllRequestTypes`);
          if (response.data.status === 'Success') {
            requestTypes.value = response.data.requestTypes;
          }
        } catch (error) {
          console.error('Error fetching request types:', error);
        }
      };
  
      const searchProjects = async () => {
        if (projectSearchText.value.trim() === '') {
          filteredProjects.value = [];
          return;
        }
        try {
          const response = await axios.get(`${BASE_HSUITE_URL}/api/Project/GetProjectsByFilter/?filter=${projectSearchText.value}&pageSize=25&pageIndex=1`);
          if (response.data.status === 'Success') {
            filteredProjects.value = response.data.projects
              .filter(project => project.enquiryID !== 0)
              .map(project => ({
                ...project,
                displayText: `${project.clientName} - ${project.enquiryID} - ${project.projectDescription}`
              }));
          }
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };
  
      const selectRequestType = (type) => {
        form.value.requestTypeID = type.requestTypeID;
        selectedRequestTypeName.value = type.requestTypeName;
      };
  
      const selectProject = (project) => {
        form.value.projectID = project.enquiryID;
        selectedProjectName.value = project.displayText;
        filteredProjects.value = [];
        projectSearchText.value = '';
      };
  
      const createWorkOrderRequest = async () => {
        // Append the time to dueDate before submitting
        form.value.dueDate = form.value.dueDate + 'T23:59:59';
        form.value.instructions = instructions.value;
        console.log(instructions.value);
  
        console.log('Form data before submission:', form.value);
  
        try {
          const response = await axios.post(`${BASE_HSUITE_URL}/api/WorkOrderRequest/CreateWorkOrderRequest`, form.value);
          console.log('API Response:', response.data);
          if (response.data.status === 'Success') {
            alert('Work order request created successfully');
          } else {
            alert('Failed to create work order request');
          }
        } catch (error) {
          console.error('Error creating work order request:', error);
        }
      };
  
      onMounted(() => {
        fetchRequestTypes();
      });
  
      return {
        form,
        requestTypes,
        filteredProjects,
        editorOptions,
        searchProjects,
        projectSearchText,
        selectedRequestTypeName,
        selectedProjectName,
        selectRequestType,
        selectProject,
        createWorkOrderRequest,
        instructions
      };
    }
  });
  </script>
  
  <style>
  /* Add your styles here */
  .create-new-project-box {
    max-width: 600px;
    margin: auto;
  }
  .selected-project {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 5px;
  }
  </style>
  