<template>
    <BreadCrumb PageTitle="Create New Outbound Order Container Analysis" />
    <div class="row">
      <div class="col-xxl-12">
        <div class="row">
          <div class="col-lg-12 d-flex">
            <div class="card mb-25 border-0 rounded-0 bg-white flex-grow-1">
              <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
                <CreateOOCAnalysis :leadID="leadID" :dataStreamID="dataStreamID" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- Add any additional components or tables here if needed -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import BreadCrumb from '../../../components/Common/DynamicBreadCrumb.vue';
  import CreateOOCAnalysis from '../../../components/ELSA/OOCAnalysis/CreateOOCAnalysis.vue';
  
  export default defineComponent({
    name: 'OOCAnalysisResultsPage',
    components: {
      BreadCrumb,
      CreateOOCAnalysis,
    },
    setup() {
      const route = useRoute();
      const leadID = ref<number | null>(null);
      const dataStreamID = ref<number | null>(null);
  
      onMounted(() => {
        const leadIDParam = route.params.leadID;
        const dataStreamIDParam = route.params.dataStreamID;
        if (typeof leadIDParam === 'string') {
          leadID.value = parseInt(leadIDParam);
        }
        if (typeof dataStreamIDParam === 'string') {
          dataStreamID.value = parseInt(dataStreamIDParam);
        }
      });
  
      return {
        leadID,
        dataStreamID,
      };
    },
  });
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  }
  
  .letter-spacing {
    letter-spacing: 0.5px;
  }
  
  .h-100 {
    height: 100%;
  }
  </style>
  