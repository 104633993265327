<template>
  <div class="update-data-stream">
    <div
      class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
    >
      <div class="card-head bg-white d-flex align-items-center">
        <i class="flaticon-sterile-box text-primary"></i>
        <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
          Role Name ({{ roleName }})
        </h5>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 ps-0">Page</th>
                <th scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 ps-0">Permissions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="page in pages" :key="page.pageKey">
                <td class="shadow-none fw-medium text-black product-title ps-0">{{ page.displayName }}</td>
                <td class="shadow-none fw-medium text-black product-title ps-0">
                  <div
                    v-for="permission in page.permissions"
                    :key="permission.permissionId"
                    class="form-check fs-md-15 fs-lg-16 text-black mb-0 d-inline-block me-15 me-md-25"
                  >
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      :id="permission.permissionId"
                      :value="permission.permissionId"
                      v-model="selectedPermissions"
                      :checked="isPermissionSelected(permission.permissionId)"
                    />
                    <label class="form-check-label" :for="permission.permissionId">
                      {{ permission.permissionDisplayName }}
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-flex align-items-center mt-4">
          <button @click="updatePermission" class="default-btn transition border-0 fw-medium text-white 
            pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
            fs-md-15 fs-lg-16 m-5 bg-success" type="button"> Save </button>
          <button @click="goToMainMenu" class="default-btn transition border-0 fw-medium text-white 
            pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
            fs-md-15 fs-lg-16 m-5 bg-danger" type="button"> Cancel </button>
        </div>

      </div>
    </div>
  </div>
</template>
  
  <script>
  import { defineComponent, ref, onMounted } from 'vue';
  import HSuiteApiService from '@/core/services/hsuiteApi.service'
  import { useRoute, useRouter } from "vue-router";
  import { useToast } from 'vue-toastification'

  export default defineComponent({
    name: 'UpdatePermission',
    
    setup() {
      const route = useRoute();
      const router = useRouter();
      const roleId = route.params.id;
      const roleName = route.params.name;

      const pages = ref([]);
      const selectedPermissions = ref([]);
  
      const isPermissionSelected = (permissionId) => {
        return selectedPermissions.value.includes(permissionId);
      };
  
      const loadPermissions = async () => {
        
        HSuiteApiService.get(`/api/ElisaAuth/GetRolePermissionsByRoleId/${roleId}`)
            .then(response => {
                  if (response.data) {
                    selectedPermissions.value = response.data.map(permission => permission.permissionId);
                  }
                  })
                  .catch(ex => {
                    console.log(ex)
                  })
      };
  
     

      onMounted(async () => {
        try {
          await loadPermissions();
          const pagesResponse = await HSuiteApiService.get('/api/ElisaAuth/GetAllPage');
          
          pages.value = await Promise.all(
            pagesResponse.data.map(async (page) => {
              try {
                const permissionsResponse = await HSuiteApiService.get(
                  `/api/ElisaAuth/GetPagePermissionsByPageKey/${page.pageKey}`
                );
                
                if (permissionsResponse.status === 200) {
                  return { ...page, permissions: permissionsResponse.data };
                } else {
                  console.error(`Error loading permissions for page ${page.pageKey}:`, permissionsResponse.status);
                  return { ...page, permissions: [] };
                }
              } catch (error) {
                if (error.response && error.response.status === 404) {
                  console.error(`Permissions not found for page ${page.pageKey}:`, error);
                  return { ...page, permissions: [] };
                } else {
                  console.error(`Error loading permissions for page ${page.pageKey}:`, error);
                  return { ...page, permissions: [] };
                }
              }
            })
          );
        } catch (error) {
          console.error('Error loading pages:', error);
        }
      });
  
      const goToMainMenu = async () => {
        router.push({ name: "RoleListPage" });
      };

      const updatePermission = async () => {
        const toast = useToast()
        const payload = {
          roleId: roleId,
          permissionIds: selectedPermissions.value
        };
  
        HSuiteApiService.post(`/api/ElisaAuth/AssignPermissionsToRole`, payload)
                  .then(response => {
                  if (response.status === 200) {
                    toast.success('Permission updated successfully!', {
                      timeout: 2000
                    })
                    goToMainMenu();
                  }
                  })
                  .catch(ex => {
                    console.log(ex)
                  })
  
      };
  
      return {
        roleId,
        roleName,
        pages,
        selectedPermissions,
        isPermissionSelected,
        updatePermission,
        goToMainMenu
      };
    }
  });
  </script>

  