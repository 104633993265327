<script>
import ProjectsListPage from "../../../pages/Projects/ProjectsListPage.vue";
import ProjectsGridPage from "../../../pages/Projects/ProjectsGridPage.vue";
import CreateNewProjectPage from "../../../pages/Projects/CreateNewProjectPage.vue";
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import { ref } from 'vue';

export default {
  name: "ProjectPanel",
  components: {
    Splitter,
    SplitterPanel,
    ProjectsListPage,
    ProjectsGridPage,
    CreateNewProjectPage
  },
  props:{
    data: {
        type: Object,
        default: null
    },
    parent: {
        type: Object,
        default: null
    }
  },
  setup(props) {
    const ContentTypeOptions = ref(
        [      
            {id: 'ProjectsListPage', label: 'Project List'},
            {id: 'ProjectsGridPage', label: 'Project Grid'},
            {id: 'CreateNewProjectPage', label: 'Project Create'},
        ],
    )
    const NewInstance = () =>
    {
        let num = Math.floor(Math.random() * 1000);
        return {
            id:num,
            size:50
        }
    }
    const CopyInstance = (el) =>
    {
        return {
            id:el.id,
            type : el.type,
            contentType:el.contentType,
            size:50
        }
    }
    const SplitHorizontal = (el) => {
        let oldEl = CopyInstance(el);
        el.contentType = null;
        el.type = 'H';
        el.childs = [];
        el.childs.push(oldEl);
        el.childs.push(NewInstance());
    }
    const SplitVertical = (el) => {
        let oldEl = CopyInstance(el);
        el.contentType = null;
        el.type = 'V';
        el.childs = [];
        el.childs.push(oldEl);
        el.childs.push(NewInstance());
    }
    const Remove = (el) => {
        if(props.data.childs.length > 0)
        {
            let index = props.data.childs.indexOf(el);
            if (index > -1) { 
                props.data.childs.splice(index, 1);
            }
            
            if(props.data.childs.length == 0)
            {
                if(props.parent)
                {
                    let indexP = props.parent.childs.indexOf(props.data);
                    if (indexP > -1) { 
                        props.parent.childs.splice(indexP, 1);
                    }
                }
            }
        }
    }
    const ResizePanel = (event, data) => {
        for (let index = 0; index < event.sizes.length; index++) {
            data.childs[index].size = Math.round((event.sizes[index] * 100) / 100);
        }
    }
    return {
        ContentTypeOptions,
        NewInstance,
        CopyInstance,
        SplitHorizontal,
        SplitVertical,
        Remove,
        ResizePanel
    };
  }
};
</script>

<template>
    <Splitter @resizeend="ResizePanel($event, data)" style="height: 100%;" :layout="data.type == 'V' ? 'vertical' : undefined">
        <SplitterPanel :size="el.size" class="flex items-center justify-center" :key="el.id" v-for="(el) in data.childs" >
            <ProjectPanel v-if="el.type && el.childs != null && el.childs.length > 0" :data="el" :parent="data" >
            </ProjectPanel>
            <template v-else>
                <div class="card h-100">
                    <div class="card-head d-flex justify-content-end">
                        <div class="button-container">
                            <select v-model="el.contentType">
                                <option :key="option.id" v-for="option in ContentTypeOptions" :value="option.id">{{option.label}}</option>
                            </select>
                            <button @click="SplitHorizontal(el)" class="text-white bg-success">Split |</button>
                            <button @click="SplitVertical(el)" class="text-white bg-success">Split -</button>
                            <button @click="Remove(el)" class="text-white bg-danger">Delete <i class="flaticon-delete"></i></button>
                        </div>
                    </div>
                    <div class="card-body" style="overflow: scroll;">
                        <component :is="el.contentType"></component>
                    </div>
                </div>
            </template>
        </SplitterPanel>
    </Splitter>
</template>

<style>
  .button-container {
        display: flex;
        gap: 5px;
        margin-right: 5px;
        margin-top: 5px;
        text-decoration: none !important;
        font-weight: 500 !important;
        border: 0;
    }
</style>