import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7f2182fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-group" }
const _hoisted_2 = ["id", "value", "checked", "onChange"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "list-group-item fs-md-15 fw-medium",
        key: index
      }, [
        _createElementVNode("input", {
          class: "form-check-input me-1",
          type: "checkbox",
          id: `checkbox-${index}`,
          value: index,
          checked: !_ctx.hiddenSeries.includes(index),
          onChange: ($event: any) => (_ctx.toggleVisibility(index))
        }, null, 40, _hoisted_2),
        _createElementVNode("label", {
          class: "form-check-label",
          for: `checkbox-${index}`
        }, _toDisplayString(label), 9, _hoisted_3)
      ]))
    }), 128))
  ]))
}