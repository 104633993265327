import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-261bea49"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12" }
const _hoisted_3 = { class: "row mt-4" }
const _hoisted_4 = { class: "col-lg-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_OrderDataStatsGrid = _resolveComponent("OrderDataStatsGrid")!
  const _component_OrderDataBarChart = _resolveComponent("OrderDataBarChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Order Data Statistics" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_OrderDataStatsGrid, {
          tableName: _ctx.tableName,
          startDate: _ctx.startDate,
          endDate: _ctx.endDate,
          onDateChange: _ctx.handleDateChange
        }, null, 8, ["tableName", "startDate", "endDate", "onDateChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OrderDataBarChart, {
          startDate: _ctx.startDate,
          endDate: _ctx.endDate,
          tableName: _ctx.tableName
        }, null, 8, ["startDate", "endDate", "tableName"])
      ])
    ])
  ]))
}