<template>
  <BreadCrumb PageTitle="Update Role Permission" />
  <UpdateRolePermission />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import UpdateRolePermission from "../../components/Roles/UpdateRolePermission.vue";

export default defineComponent({
  name: "UpdateRolePermissionPage",
  components: {
    BreadCrumb,
    UpdateRolePermission,
  },
});
</script>