<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
        <div class="d-sm-flex align-items-center">
          
        </div>
        <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
          
          <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchAllNotifications('')">
            <option :value="5">5</option>
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
          </select>
        </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <!-- <div
        class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
      >
        <h5 class="card-title fw-bold mb-0">Notifications</h5>
        
      </div> -->
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Title
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Description
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notification in notifications" :key="notification.id">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis ps-0">
                <a
                  href="notifications.html"
                  class="text-decoration-none text-black"
                >
                  {{notification.title}}
                </a>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis ps-0">
                <a
                  href="notifications.html"
                  class="text-decoration-none text-black"
                >
                  {{notification.description}}
                </a>
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">{{formatDate(notification.createdAt)}}</td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#" @click.prevent="deleteNotification(notification.id)"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>

      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
          <p class="mb-0 text-paragraph">
            Showing <span class="fw-bold">{{ notifications.length }}</span> out of
            <span class="fw-bold">{{ totalNotifications }}</span> results
          </p>
          <nav class="mt-15 mt-md-0">
            <ul class="pagination mb-0">
              <li class="page-item" :class="{ disabled: pageIndex === 1 }">
                <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                  <i class="flaticon-chevron-1"></i>
                </a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
                <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                  <i class="flaticon-chevron"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed} from "vue";
import { useStore } from 'vuex'
import HSuiteApiService from '@/core/services/hsuiteApi.service'
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification'

export default {
  name: "NotificationsContent",
  setup(){
    const notifications=ref([]);
    const store = useStore()
    const currentUser = computed(() => store.state.auth.user)
    const totalNotifications=ref(0);
    const pageSize=ref(10);
    const pageIndex=ref(1);
    const totalPages=ref(1);
    
    const changePage = (page)=> {
        if (page > 0 && page <= totalPages.value) {
          pageIndex.value = page;
          fetchAllNotifications("");
        }
      }
    const fetchAllNotifications = (filter="") => {
      
      if (!currentUser.value?.elisaUser) return;
      HSuiteApiService.get(`/api/ELISA/GetNotificationsByFilter`,`?filter=${filter}&pageSize=${pageSize.value}&pageIndex=${pageIndex.value}`)
                      .then(response=>{
                        
                        const data = response.data;
                        if (data) {
                          notifications.value = data.notifications;
                          totalNotifications.value = data.totalNotifications;
                          totalPages.value = Math.ceil(totalNotifications.value / pageSize.value);
                        }
                      }).catch(error => {
                        console.error('Error fetching projects:', error);
                      });
    }

    const MarkAsReadAllUnreadNotifications = () => {
      
      if (!currentUser.value?.elisaUser) return;
      const payload = { assignedToUserId: currentUser.value.elisaUser.id };
      HSuiteApiService.post(`/api/ELISA/MarkAsReadAllUnreadNotifications`,payload)
                      .then(response=>{
                        
                        const data = response.data;
                        if (data) {
                          console.log(data);
                        }
                      }).catch(error => {
                        console.error('Error fetching projects:', error);
                      });
    }

    const deleteNotification=(notificationId)=> {
        const toast = useToast();

        // Show confirmation dialog using SweetAlert2
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {  
          if (result.isConfirmed) {
           
              const payload = { id: notificationId };
              
              HSuiteApiService.post(`/api/ELISA/DeleteNotification`,payload)
                              .then(response=>{
                        
                                    if (response.status === 200) {
                                      toast.success('Notification deleted successfully!', {
                                        timeout: 2000,
                                      });
                                      fetchAllNotifications();
                                    }

                              }).catch(error => {
                                toast.error(`Failed to delete notification: ${error}`, {
                                  timeout: 2000,
                                });
                              });
              

          } else {
            toast.info('Notification deletion canceled.', { timeout: 2000 });
          }
        });

      }

    const formatDate = (dateString) => {
      return format(new Date(dateString), 'dd MMM yyyy'); // Format: "10 Mar 2023"
    };

    onMounted(() => {
      fetchAllNotifications();
      MarkAsReadAllUnreadNotifications();
    });

    return {
      notifications,
      currentUser,
      formatDate,
      deleteNotification,
      changePage,
      pageSize,
      fetchAllNotifications,
      totalNotifications,
      totalPages,
      pageIndex
    };
  }
};
</script>