<template>
    <BreadCrumb PageTitle="Add Role" />
    <AddRole />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddRole from "../../components/Roles/AddRole.vue";
  
  export default defineComponent({
    name: "AddRolePage",
    components: {
      BreadCrumb,
      AddRole,
    },
  });
  </script>