import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content d-flex flex-column transition overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_ctx.$route.fullPath == '/login')
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_MainHeader),
        _createVNode(_component_MainSidebar),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.fullPath
          })),
          _createVNode(_component_MainFooter)
        ])
      ], 64))
}