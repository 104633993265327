<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-product-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form @submit.prevent="submitForm">
        <div class="row">
          <!-- Order Data Table Selection -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Order Data Table</label>
              <select class="form-control shadow-none rounded-0 text-black" v-model="form.OrderDataTableName">
                <option v-for="table in orderTables" :key="table.tableID" :value="table.tableID">{{ table.name }}</option>
              </select>
            </div>
          </div>

          <!-- SKU Data Table Selection -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">SKU Data Table</label>
              <select class="form-control shadow-none rounded-0 text-black" v-model="form.SKUDataTableName">
                <option v-for="table in skuTables" :key="table.tableID" :value="table.tableID">{{ table.name }}</option>
              </select>
            </div>
          </div>

          <!-- Start Date Selection -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Start Date</label>
              <input
                type="date"
                class="form-control shadow-none rounded-0 text-black"
                v-model="form.StartDate"
              />
            </div>
          </div>

          <!-- End Date Selection -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">End Date</label>
              <input
                type="date"
                class="form-control shadow-none rounded-0 text-black"
                v-model="form.EndDate"
              />
            </div>
          </div>

          <!-- Outbound Order Containers -->
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Outbound Order Containers</label>
              <div v-if="form.OutboundOrderContainers.length > 0" class="d-flex mb-10">
                <span class="fw-semibold me-10" style="flex: 1;">Width</span>
                <span class="fw-semibold me-10" style="flex: 1;">Depth</span>
                <span class="fw-semibold me-10" style="flex: 1;">Height</span>
                <span class="fw-semibold me-10" style="flex: 2;">Name</span>
              </div>
              <div v-for="(container, index) in form.OutboundOrderContainers" :key="index" class="d-flex mb-10">
                <input type="number" v-model.number="container.Width" placeholder="Width" class="form-control mr-10" style="flex: 1;"/>
                <input type="number" v-model.number="container.Depth" placeholder="Depth" class="form-control mr-10" style="flex: 1;"/>
                <input type="number" v-model.number="container.Height" placeholder="Height" class="form-control mr-10" style="flex: 1;"/>
                <input type="text" v-model="container.Name" placeholder="Name" class="form-control mr-10" style="flex: 2;"/>
                <button @click="removeContainer(index)" type="button" class="btn btn-danger">Remove</button>
              </div>
              <button @click="addContainer" type="button" class="btn btn-primary mt-10">Add Container</button>
              <button @click="addStandardContainers" type="button" class="btn btn-secondary mt-10">Add Standard</button>
            </div>
          </div>

          <!-- Filters -->
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Filters</label>
              <div v-for="(filter, index) in form.Filters" :key="index" class="d-flex mb-10">
                <input type="text" v-model="filter.Key" placeholder="Key" class="form-control mr-10"/>
                <input type="text" v-model="filter.Value" placeholder="Value" class="form-control mr-10"/>
                <button @click="removeFilter(index)" type="button" class="btn btn-danger">Remove</button>
              </div>
              <button @click="addFilter" type="button" class="btn btn-primary mt-10">Add Filter</button>
            </div>
          </div>

          <!-- Submit Button -->
          <div class="col-md-12">
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


  
<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { BASE_HSUITE_URL } from '@/core/constant/env';

// Define types
interface OutboundOrderContainer {
  Name: string;
  Width: number;
  Height: number;
  Depth: number;
}

interface Filter {
  Key: string;
  Value: string;
}

interface Form {
  OrderDataTableName: string;
  SKUDataTableName: string;
  StartDate: string;
  EndDate: string;
  OutboundOrderContainers: OutboundOrderContainer[];
  Filters: Filter[];
}

export default defineComponent({
  name: "CreateRetrievalAnalysis",
  props: {
    dataStreamID: {
      type: Number,
      required: true,
    },
    leadID: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const orderTables = ref<any[]>([]);
    const skuTables = ref<any[]>([]);
    const form = ref<Form>({
      OrderDataTableName: "",
      SKUDataTableName: "",
      StartDate: "",
      EndDate: "",
      OutboundOrderContainers: [],
      Filters: []
    });

    const router = useRouter();

    const fetchOrderTables = async () => {
      try {
        console.log(`Fetching Order Tables with LeadID=${props.leadID}`);
        const response = await axios.get(`${BASE_HSUITE_URL}/api/DataTable/GetFilteredDataTables?LeadID=${props.dataStreamID}&tableID=OrderData`);
        orderTables.value = response.data.dataTables;
        console.log("Order Tables fetched successfully:", response.data);
      } catch (error) {
        console.error("Error fetching Order Tables:", error);
      }
    };

    const fetchSkuTables = async () => {
      try {
        console.log(`Fetching SKU Tables with LeadID=${props.leadID}`);
        const response = await axios.get(`${BASE_HSUITE_URL}/api/DataTable/GetFilteredDataTables?LeadID=${props.dataStreamID}&tableID=SKUData`);
        skuTables.value = response.data.dataTables;
        console.log("SKU Tables fetched successfully:", response.data);
      } catch (error) {
        console.error("Error fetching SKU Tables:", error);
      }
    };

    watch(
      () => props.leadID,
      (newLeadID) => {
        console.log(`leadID changed to: ${newLeadID}`);
        fetchOrderTables();
        fetchSkuTables();
      },
      { immediate: true }
    );

    const addContainer = () => {
      form.value.OutboundOrderContainers.push({ Name: "", Width: 0, Height: 0, Depth: 0 });
    };

    const removeContainer = (index: number) => {
      form.value.OutboundOrderContainers.splice(index, 1);
    };

    const addFilter = () => {
      form.value.Filters.push({ Key: "", Value: "" });
    };

    const removeFilter = (index: number) => {
      form.value.Filters.splice(index, 1);
    };

    const addStandardContainers = () => {
      const standardContainers: OutboundOrderContainer[] = [
        { Name: "Linbin: Size 03", Width: 105, Height: 75, Depth: 190 },
        { Name: "Linbin: Size 05", Width: 140, Height: 130, Depth: 280 },
        { Name: "Linbin: Size 07", Width: 210, Height: 180, Depth: 375 },
        { Name: "Linbin: Size 08", Width: 420, Height: 180, Depth: 375 },
        { Name: "Linbin: Size 09", Width: 210, Height: 230, Depth: 455 },
        { Name: "Linbin: Size 10", Width: 420, Height: 295, Depth: 455 },
        { Name: "Tote: Standard", Width: 400, Height: 400, Depth: 600 },
        { Name: "Tote: Oversized", Width: 600, Height: 600, Depth: 800 },
        { Name: "Pallet: UK", Width: 1200, Height: 1200, Depth: 800 },
      ];
      form.value.OutboundOrderContainers.push(...standardContainers);
    };

    const submitForm = async () => {
      try {
        console.log("Submitting form with data:", form.value);
        const OutboundOrderContainersJson = JSON.stringify(form.value.OutboundOrderContainers);
        const Filters = form.value.Filters.reduce((acc, filter) => {
          if (filter.Key && filter.Value) {
            if (!acc[filter.Key]) {
              acc[filter.Key] = [];
            }
            acc[filter.Key].push(filter.Value);
          }
          return acc;
        }, {} as Record<string, string[]>);

        await axios.post(`${BASE_HSUITE_URL}/api/OOCAnalysis/ProcessOrders`, {
          OrderDataTableName: form.value.OrderDataTableName,
          SKUDataTableName: form.value.SKUDataTableName,
          StartDate: form.value.StartDate,
          EndDate: form.value.EndDate,
          Filters,
          OutboundOrderContainersJson,
          DataStreamID: props.dataStreamID,
        });
        alert("The analysis will continue to run in the background. The completed results tables will be added to the datastream when it is complete.");
        router.push({ name: 'DataTableOverview', params: { leadID: props.leadID, dataStreamID: props.dataStreamID } });
      } catch (error) {
        console.error("Form submission error:", error);
      }
    };

    return { form, orderTables, skuTables, addContainer, removeContainer, addFilter, removeFilter, addStandardContainers, submitForm };
  },
});
</script>


  
  <style>
  /* Add any additional styling here */
  </style>
  