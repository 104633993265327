<template>
  <BreadCrumb PageTitle="Projects List" />
  <ProjectsList @view-sku-data-tables="navigateToSkuDataTables" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ProjectsList from "../../components/Projects/ProjectsList/ProjectsList.vue";

export default defineComponent({
  name: "ProjectsListPage",
  components: {
    BreadCrumb,
    ProjectsList,
  },
  setup() {
    const router = useRouter();

    const navigateToSkuDataTables = (enquiryID) => {
      router.push({ name: 'SKUDataTableListPage', params: { enquiryID } });
    };

    return {
      navigateToSkuDataTables
    };
  }
});
</script>
