<template>
  <BreadCrumb PageTitle="Triple System Analysis" />
    <div class="row">
      <div class="col-12">
        <TripleSystemAnalyticsResultsTable :tableName="tableName" />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import BreadCrumb from "@/components/Common/DynamicBreadCrumb.vue";
  import TripleSystemAnalyticsResultsTable from "@/components/ELSA/TripleSystemAnalysis/TripleSystemAnalyticsResultsTable.vue";
  
  export default defineComponent({
    name: "TripleSystemAnalyticsPage",
    components: {
      BreadCrumb,
      TripleSystemAnalyticsResultsTable,
    },
    props: {
      tableName: {
        type: String,
        required: true,
      },
    },
  });
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  }
  
  .letter-spacing {
    letter-spacing: 0.5px;
  }
  
  .h-100 {
    height: 100%;
  }
  </style>