<template>
  <div>
    <div class="btn-group mb-3" role="group">
      <div class="d-flex flex-wrap">
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off" v-model="selectedViews.basic">
          <label class="btn btn-outline-primary btn-sm" for="btncheck1">Basic</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off" v-model="selectedViews.stock">
          <label class="btn btn-outline-primary btn-sm" for="btncheck2">Stock</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off" v-model="selectedViews.sales">
          <label class="btn btn-outline-primary btn-sm" for="btncheck3">Sales</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck4" autocomplete="off" v-model="selectedViews.unit">
          <label class="btn btn-outline-primary btn-sm" for="btncheck4">Unit Dims</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck5" autocomplete="off" v-model="selectedViews.inner">
          <label class="btn btn-outline-primary btn-sm" for="btncheck5">Inner Dims</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck6" autocomplete="off" v-model="selectedViews.outer">
          <label class="btn btn-outline-primary btn-sm" for="btncheck6">Outer Dims</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck7" autocomplete="off" v-model="selectedViews.misc">
          <label class="btn btn-outline-primary btn-sm" for="btncheck7">Misc.</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck8" autocomplete="off" v-model="selectedViews.characteristics">
          <label class="btn btn-outline-primary btn-sm" for="btncheck8">Characteristics</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck9" autocomplete="off" v-model="selectedViews.band">
          <label class="btn btn-outline-primary btn-sm" for="btncheck9">Band Info</label>
        </div>
        <div class="p-1">
          <input type="checkbox" class="btn-check" id="btncheck10" autocomplete="off" v-model="selectedViews.systems">
          <label class="btn btn-outline-primary btn-sm" for="btncheck10">Opt. & Feas.</label>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="(header, index) in filteredHeaders" :key="index">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td v-for="(header, idx) in filteredHeaders" :key="idx">{{ formatValue(item[header]) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav>
      <ul class="pagination mb-0">
        <li class="page-item" :class="{ disabled: pageIndex <= 1 }">
          <a class="page-link transition fw-medium shadow-none text-primary" href="#" @click.prevent="prevPage">Previous</a>
        </li>
        <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ active: page === pageIndex }">
          <a class="page-link transition fw-medium shadow-none text-primary" href="#" @click.prevent="goToPage(page)">
            {{ page }}
          </a>
        </li>
        <li class="page-item" :class="{ disabled: pageIndex >= totalPages }">
          <a class="page-link transition fw-medium shadow-none text-primary" href="#" @click.prevent="nextPage">Next</a>
        </li>
      </ul>
    </nav>
    <div class="mt-2 text-center">
      Page {{ pageIndex }} of {{ totalPages }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, computed } from 'vue';
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: 'SKUDataTable',
  props: {
    headers: {
      type: Object,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const data = ref<any[]>([]);
    const pageIndex = ref(1);
    const pageSize = ref(10);
    const totalRecords = ref(0);

    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          ...props.filters,
          pageSize: pageSize.value.toString(),
          pageIndex: pageIndex.value.toString(),
        }).toString();

        const response = await fetch(`${BASE_HSUITE_URL}/api/elsa/GetDataFromSKUDataTable/${props.tableName}?${queryParams}`);
        const result = await response.json();

        data.value = result.Data;
        totalRecords.value = result.TotalRecords;

        // Debug logs
        console.log('Fetched data:', result.Data);
        console.log('Total records:', result.TotalRecords);
        console.log('Filtered headers:', filteredHeaders.value);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const totalPages = computed(() => Math.ceil(totalRecords.value / pageSize.value));

    const visiblePages = computed<(number | string)[]>(() => {
      const pages: (number | string)[] = [];
      const maxPagesToShow = 5; // Maximum number of pages to show in pagination control
      const current = pageIndex.value;
      const total = totalPages.value;
      const half = Math.floor(maxPagesToShow / 2);

      let start = Math.max(2, current - half);
      let end = Math.min(total - 1, current + half);

      if (current <= half) {
        end = Math.min(1 + maxPagesToShow, total - 1);
      }

      if (current >= total - half) {
        start = Math.max(total - maxPagesToShow, 2);
      }

      if (start > 2) {
        pages.push(1, '...');
      } else {
        pages.push(1);
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < total - 1) {
        pages.push('...', total);
      } else {
        pages.push(total);
      }

      return pages;
    });

    const prevPage = () => {
      if (pageIndex.value > 1) {
        pageIndex.value--;
        fetchData();
      }
    };

    const nextPage = () => {
      if (pageIndex.value < totalPages.value) {
        pageIndex.value++;
        fetchData();
      }
    };

    const goToPage = (page: number | string) => {
      if (page !== '...') {
        pageIndex.value = page as number;
        fetchData();
      }
    };

    const formatValue = (value: any) => {
      if (typeof value === 'number') {
        return value.toFixed(value % 1 === 0 ? 0 : 2);
      }
      return value;
    };

    const selectedViews = ref({
      basic: true,
      stock: true,
      sales: true,
      unit: true,
      inner: false,
      outer: false,
      misc: false,
      characteristics: false,
      band: false,
      systems: false,
    });

    const filteredHeaders = computed(() => {
      const activeHeaders: string[] = [];
      console.log('Selected views:', selectedViews.value); // Log selected views
      console.log('Available headers:', props.headers); // Log available headers

      (Object.keys(selectedViews.value) as (keyof typeof selectedViews.value)[]).forEach((view) => {
        if (selectedViews.value[view] && props.headers[view]) {
          activeHeaders.push(...props.headers[view]);
        }
      });
      console.log('Active headers before deduplication:', activeHeaders); // Log active headers before deduplication

      return Array.from(new Set(activeHeaders)); // Ensure SKUID is not repeated
    });

    watch([pageIndex, props.filters, selectedViews], fetchData, { immediate: true });

    onMounted(fetchData);

    return {
      data,
      pageIndex,
      totalPages,
      visiblePages,
      prevPage,
      nextPage,
      goToPage,
      formatValue,
      selectedViews,
      filteredHeaders,
    };
  },
});
</script>

<style scoped>
.table-responsive {
  margin-bottom: 20px;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-item.active .page-link {
  background-color: #007bff; /* Change to your desired background color */
  color: white; /* Change to your desired text color */
}

.btn-group .btn-check:checked + .btn {
  background-color: #007bff;
  color: white;
}

.btn-group .btn {
  margin-right: 5px;
}

.table-sm th, .table-sm td {
  font-size: 10px;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
}

.btn-group .p-1 {
  flex: 1 1 auto;
}
</style>
