<template>
    <div class="update-data-stream">
      <h2>Update Data Request</h2>
      <div class="form-group mb-4">
        <label for="name" class="form-label">Name</label>
        <input
          type="text"
          id="name"
          class="form-control"
          v-model="name"
          placeholder="Enter data stream name"
        />
      </div>
      <div class="form-group mb-4">
        <label for="description" class="form-label">Description</label>
        <textarea
          id="description"
          class="form-control"
          v-model="description"
          placeholder="Enter data stream description"
          rows="4"
        ></textarea>
      </div>
      <div class="form-buttons">
        <button class="btn btn-primary" @click="updateDataStream">Update Data Stream</button>
        <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import axios from 'axios';
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  
  export default defineComponent({
    name: 'UpdateDataStream',
    props: {
      id: {
        type: Number,
        required: true
      },
      leadID: {
        type: Number,
        required: true
      },
      initialName: {
        type: String,
        required: true
      },
      initialDescription: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const name = ref(props.initialName);
      const description = ref(props.initialDescription);
  
      const updateDataStream = async () => {
        try {
          console.log('Updating DataStream with the following details:', {
            id: props.id,
            leadID: props.leadID,
            name: name.value,
            description: description.value
          });
          const response = await axios.put(`${BASE_HSUITE_URL}/api/DataStream/UpdateDataStream`, {
            id: props.id,
            leadID: props.leadID,
            name: name.value,
            description: description.value
          });
  
          console.log('API Response:', response.data);
  
          if (response.data.status === 'Success') {
            alert('Data stream updated successfully!');
            emit('dataStreamUpdated'); // Emit the event to notify the parent component
            emit('close'); // Close the lightbox after updating data stream
          } else {
            console.error('Error response:', response.data);
            alert('Failed to update data stream.');
          }
        } catch (error) {
          console.error('Error updating data stream:', error);
          alert('Error updating data stream.');
        }
      };
  
      return {
        name,
        description,
        updateDataStream
      };
    }
  });
  </script>
  
  <style scoped>
  .update-data-stream {
    width: 500px; /* Double the size */
    height: 400px; /* Double the size */
    padding: 40px; /* Increase padding */
    background-color: #fff; /* Ensure background is white */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .btn {
    padding: 0.5rem 1rem;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  </style>
  