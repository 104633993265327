<template>
    <BreadCrumb PageTitle="Update User Role" />
    <UpdateUserRole />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import UpdateUserRole from "../../components/Users/UpdateUserRole.vue";
  
  export default defineComponent({
    name: "UpdateUserRolePage",
    components: {
      BreadCrumb,
      UpdateUserRole,
    },
  });
  </script>