import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5b685e85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12" }
const _hoisted_3 = { class: "card mb-25 border-0 rounded-0 bg-white sales-pos-location-box" }
const _hoisted_4 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }
const _hoisted_5 = { class: "col-lg-12" }
const _hoisted_6 = { class: "card mb-25 border-0 rounded-0 bg-white sales-pos-location-box" }
const _hoisted_7 = { class: "card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_RetrievalSimulationAnalysis = _resolveComponent("RetrievalSimulationAnalysis")!
  const _component_RetrievalSimulationPercentileAnalysis = _resolveComponent("RetrievalSimulationPercentileAnalysis")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Retrieval Analysis Results" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_RetrievalSimulationAnalysis, {
              tableName: _ctx.tableName,
              onDateChange: _ctx.handleDateChange
            }, null, 8, ["tableName", "onDateChange"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_RetrievalSimulationPercentileAnalysis, {
              tableName: _ctx.tableName,
              startDate: _ctx.startDate,
              endDate: _ctx.endDate
            }, null, 8, ["tableName", "startDate", "endDate"])
          ])
        ])
      ])
    ])
  ], 64))
}