<template>
    <BreadCrumb PageTitle="Create New User Layout" />
    <CreateNewUserLayout />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import CreateNewUserLayout from "../../components/UserLayouts/CreateNewUserLayout.vue";
  
  export default defineComponent({
    name: "CreateUserLayoutPage",
    components: {
      BreadCrumb,
      CreateNewUserLayout,
    },
  });
  </script>