<template>
  <div class="card mb-25 border-0 rounded-0 bg-white" id="chartCard">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="d-flex justify-content-between mb-6">
        <span class="fs-20 text-uppercase fw-bold text-dark-emphasis">
          Suggested System - Pie Chart
        </span>
        <div class="dropdown">
          <button
            class="dropdown-toggle card-dot-btn lh-1 bg-transparent border-0 shadow-none p-0 transition"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="flaticon-dots"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="exportChart('jpg')"
              >
                <i
                  class="flaticon-download lh-1 me-8 position-relative top-1"
                ></i>
                Export as JPG
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="exportChart('png')"
              >
                <i
                  class="flaticon-download lh-1 me-8 position-relative top-1"
                ></i>
                Export as PNG
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <div class="btn-group" role="group" aria-label="Basic outlined example">
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="{ active: activeMetric === 'SKUQuantity' }"
            @click="setMetric('SKUQuantity')"
          >
            SKU Quantity
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="{ active: activeMetric === 'VolumeInStock' }"
            @click="setMetric('VolumeInStock')"
          >
            Volume In Stock
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="{ active: activeMetric === 'VolumePerDay' }"
            @click="setMetric('VolumePerDay')"
          >
            Volume Per Day
          </button>
        </div>
      </div>
      <div class="d-flex">
        <div id="suggestedSystemLegend" class="legend me-3"></div>
        <div id="suggestedSystemChart" class="chart">
          <div id="chartContainer">
            <apexchart
              ref="apexChart"
              type="pie"
              height="300"
              :options="chartOptions"
              :series="filteredSeries"
              :id="'chartContainer'"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import ApexCharts, { ApexOptions } from "apexcharts";
import html2canvas from "html2canvas";
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "SuggestedSystemPieChart",
  props: {
    tableName: {
      type: String,
      required: true,
    },
    hiddenSeries: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartSeries = ref<number[]>([]);
    const filteredSeries = ref<number[]>([]);
    const filteredLabels = ref<string[]>([]);
    const originalColors = ["#06B48A", "#6560F0", "#6FD3F7", "#F7B924", "#F7464A", "#8E44AD"];
    const filteredColors = ref<string[]>([]);

    const chartOptions = ref<ApexOptions>({
      labels: filteredLabels.value,
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.labels[opts.seriesIndex];
        },
        style: {
          colors: ["#FFFFFF"],
          fontSize: "12px",
          fontFamily: "Red Hat Display, sans-serif",
          fontWeight: 500,
        },
        dropShadow: {
          enabled: false,
        },
      },
      colors: filteredColors.value,
      legend: {
        show: true,
        position: "left",
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Red Hat Display, sans-serif",
        formatter: (seriesName, opts) => {
          const total = opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0);
          const value = opts.w.globals.series[opts.seriesIndex];
          const percentage = ((value / total) * 100).toFixed(2);
          return `${seriesName}: ${percentage}% (${Number(value).toFixed(2)})`;
        },
        labels: {
          colors: "#9C9AB6",
        },
        markers: {
          offsetX: -2,
          offsetY: 1,
        },
        itemMargin: {
          horizontal: 10,
          vertical: 5,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      stroke: {
        width: 0,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val.toFixed(2);
          },
        },
        style: {
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
        },
      },
    });

    const activeMetric = ref('SKUQuantity');
    const rawData = ref<any[]>([]);
    const apexChart = ref<ApexCharts | null>(null);

    const setMetric = (metric: string) => {
      activeMetric.value = metric;
      updateSeries();
    };

    const updateSeries = () => {
      console.log('Updating series...');
      console.log('useHiddenSeries - hiddenSeries:', props.hiddenSeries);

      chartSeries.value = rawData.value.map((item, index) => {
        const isHidden = props.hiddenSeries.includes(index);
        console.log(`Index ${index} isHidden: ${isHidden}`);
        return isHidden ? 0 : item[activeMetric.value];
      });

      filteredSeries.value = chartSeries.value.filter((value, index) => !props.hiddenSeries.includes(index));
      filteredLabels.value = rawData.value
        .map((item, index) => (!props.hiddenSeries.includes(index) ? item.SuggestedSystem : null))
        .filter((label) => label !== null);
      filteredColors.value = originalColors.filter((color, index) => !props.hiddenSeries.includes(index));

      console.log('Updated filteredSeries:', filteredSeries.value);
      console.log('Updated filteredLabels:', filteredLabels.value);
      console.log('Updated filteredColors:', filteredColors.value);

      if (apexChart.value) {
        apexChart.value.updateOptions({
          labels: filteredLabels.value,
          colors: filteredColors.value,
        });
        apexChart.value.updateSeries(filteredSeries.value);
      } else {
        console.error('Chart not found');
      }
    };

    watch(
      () => props.hiddenSeries,
      (newVal) => {
        console.log('hiddenSeries changed:', newVal);
        updateSeries();
      },
      { deep: true }
    );

    const exportChart = async (format: string) => {
      const chartCard = document.getElementById('chartCard');
      if (!chartCard) return;

      const canvas = await html2canvas(chartCard, {
        backgroundColor: format === 'jpg' ? '#FFFFFF' : null
      });
      const dataURL = canvas.toDataURL(`image/${format}`);
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `SuggestedSystemChart.${format}`;
      link.click();
    };

    onMounted(async () => {
      try {
        const response = await fetch(`${BASE_HSUITE_URL}/api/elsa/GetSuggestedSystemResults/${props.tableName}`);
        rawData.value = await response.json();

        chartOptions.value = {
          ...chartOptions.value,
          labels: rawData.value.map((item: any) => item.SuggestedSystem)
        };
        updateSeries();
        console.log('Component mounted and data fetched:', rawData.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    return {
      chartSeries,
      filteredSeries,
      chartOptions,
      activeMetric,
      setMetric,
      exportChart,
      apexChart,
    };
  },
});
</script>

<style scoped>
.chart {
  width: 100%;
  height: 300px;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.btn-group .btn {
  margin-right: 0.5rem;
}

.btn-group .btn.active {
  background-color: #0069d9;
  color: white;
}

.dropdown-toggle.card-dot-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  min-width: 150px;
}

.dropdown-item i {
  margin-right: 10px;
}

#chartContainer {
  position: relative;
}

#chartTitle {
  text-align: center;
}
</style>
