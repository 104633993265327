<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-product-box">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
        <form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Order Table ID</label>
                <select class="form-control shadow-none rounded-0 text-black" v-model="form.OrderTableID">
                  <option v-for="table in orderTables" :key="table.tableID" :value="table.tableID">{{ table.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">SKU Table ID</label>
                <select class="form-control shadow-none rounded-0 text-black" v-model="form.SKUTableID">
                  <option v-for="table in skuTables" :key="table.tableID" :value="table.tableID">{{ table.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Start Date</label>
                <input
                  type="date"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.StartDate"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">End Date</label>
                <input
                  type="date"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.EndDate"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Unit Of 1 Waves</label>
                <input
                  type="number"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.UnitOf1Waves"
                  min="1"
                  step="1"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Wave Order Quantity</label>
                <input
                  type="number"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.WaveOrderQuantity"
                  min="50"
                  step="50"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Start Time</label>
                <input
                  type="time"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.StartTime"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">End Time</label>
                <input
                  type="time"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.EndTime"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Create Results Tables</label>
                <input
                  type="checkbox"
                  v-model="form.CreateResultsTables"
                />
              </div>
            </div>
            <div class="col-md-12">
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, watch } from "vue";
  import axios from "axios";
  import { useRouter } from "vue-router";
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  
  export default defineComponent({
    name: "CreateRetrievalAnalysis",
    props: {
      dataStreamID: {
        type: Number,
        required: true,
      },
      leadID: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const orderTables = ref([]);
      const skuTables = ref([]);
      const form = ref({
        OrderTableID: "",
        SKUTableID: "",
        StartDate: "",
        EndDate: "",
        UnitOf1Waves: 1,
        WaveOrderQuantity: 50,
        StartTime: "08:00",
        EndTime: "20:00",
        CreateResultsTables: false,
      });
  
      const router = useRouter();
  
      const fetchOrderTables = async () => {
        try {
          console.log(`Fetching Order Tables with LeadID=${props.leadID}`);
          const response = await axios.get(`${BASE_HSUITE_URL}/api/DataTable/GetFilteredDataTables?LeadID=${props.dataStreamID}&tableID=OrderData`);
          orderTables.value = response.data.dataTables;
          console.log("Order Tables fetched successfully:", response.data);
        } catch (error) {
          console.error("Error fetching Order Tables:", error);
        }
      };
  
      const fetchSkuTables = async () => {
        try {
          console.log(`Fetching SKU Tables with LeadID=${props.leadID}`);
          const response = await axios.get(`${BASE_HSUITE_URL}/api/DataTable/GetFilteredDataTables?LeadID=${props.dataStreamID}&tableID=SKUData`);
          skuTables.value = response.data.dataTables;
          console.log("SKU Tables fetched successfully:", response.data);
        } catch (error) {
          console.error("Error fetching SKU Tables:", error);
        }
      };
  
      watch(
        () => props.leadID,
        (newLeadID) => {
          console.log(`leadID changed to: ${newLeadID}`);
          fetchOrderTables();
          fetchSkuTables();
        },
        { immediate: true }
      );
  
      const submitForm = async () => {
        try {
          console.log("Submitting form with data:", form.value);
          // Send the form data without waiting for the response
          axios.post(`${BASE_HSUITE_URL}/api/RetrievalQuantityAnalysis/RunFullSimulation`, {
            OrderTableID: form.value.OrderTableID,
            SKUTableID: form.value.SKUTableID,
            StartDate: form.value.StartDate,
            EndDate: form.value.EndDate,
            UnitOf1Waves: form.value.UnitOf1Waves,
            StartTime: `2023-01-01T${form.value.StartTime}:00`,
            EndTime: `2023-01-01T${form.value.EndTime}:00`,
            WaveOrderQuantity: form.value.WaveOrderQuantity,
            CreateResultsTables: form.value.CreateResultsTables,
            DataStreamID: props.dataStreamID,
          });
          alert("The analysis will continue to run in the background. The completed results tables will be added to the datastream when it is complete.");
          router.push({ name: 'DataTableOverview', params: { leadID: props.leadID, dataStreamID: props.dataStreamID } });
        } catch (error) {
          console.error("Form submission error:", error);
        }
      };
  
      return { form, orderTables, skuTables, submitForm };
    },
  });
  </script>
  
  <style>
  /* Add any additional styling here */
  </style>
  