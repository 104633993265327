<template>
  <div class="card mb-25 border-0 rounded-0 bg-white number-of-tickets-box" id="chartCard">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="d-sm-flex align-items-center justify-content-between">
        <h6 class="card-title fw-bold mb-0">Order Data Overview</h6>
        <div class="d-flex align-items-center">
          <div class="card-select me-2">
            <select
              class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium"
              v-model="selectedPeriod"
              @change="fetchChartData"
            >
              <option value="minute">Minute</option>
              <option value="day">Day</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
          <div class="dropdown ellipsis-menu" ref="ellipsisMenu">
            <button
              class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-dots"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="exportChart('png')">
                  <i class="flaticon-download lh-1 me-8 position-relative top-1"></i>
                  Export as PNG
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="exportChart('jpg')">
                  <i class="flaticon-download lh-1 me-8 position-relative top-1"></i>
                  Export as JPG
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="exportChartAsIframe">
                  <i class="flaticon-code lh-1 me-8 position-relative top-1"></i>
                  Export as Iframe
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="orderDataChart" class="chart">
        <apexchart
          type="bar"
          height="300"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import ApexCharts from "apexcharts";
import html2canvas from "html2canvas";
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "OrderDataBarChart",
  props: {
    tableName: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const series = ref([
      {
        name: "Order Lines per Period",
        data: [],
      },
      {
        name: "Orders per Period",
        data: [],
      },
      {
        name: "Unique SKUs per Period",
        data: [],
      },
    ]);

    const chartOptions = ref({
      chart: {
        type: "bar",
        height: 300,
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (val) => {
            const date = new Date(val);
            return date.toLocaleDateString("en-GB"); // Format as dd/mm/yyyy
          },
        },
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          formatter: (val) => new Date(val).toLocaleDateString("en-GB"), // Format as dd/mm/yyyy
        },
        y: {
          formatter: (val) => val.toLocaleString(),
        },
      },
    });

    const selectedPeriod = ref("day");

    const fetchChartData = async () => {
      console.log(`Fetching chart data with period=${selectedPeriod.value}, startDate=${props.startDate}, endDate=${props.endDate}`);
      if (props.startDate && props.endDate) {
        const url = `${BASE_HSUITE_URL}/api/OrderDataAnalysis/GetPeriodSummaryData?tableName=${props.tableName}&period=${selectedPeriod.value}&startDate=${props.startDate}T00:00:00&endDate=${props.endDate}T23:59:59`;
        try {
          const response = await axios.get(url);
          console.log("API response:", response.data);
          if (response.data.status === "Success") {
            const data = response.data.data;
            series.value[0].data = data.map((item) => [new Date(item.period).getTime(), item.totalOrderLines]);
            series.value[1].data = data.map((item) => [new Date(item.period).getTime(), item.totalUniqueOrders]);
            series.value[2].data = data.map((item) => [new Date(item.period).getTime(), item.totalUniqueSKUs]);
            chartOptions.value.xaxis.categories = data.map((item) => new Date(item.period).getTime());
            console.log("Updated series:", series.value);
            console.log("Updated chartOptions:", chartOptions.value);
          }
        } catch (error) {
          console.error("Error fetching chart data:", error);
        }
      }
    };

    const exportChart = async (format: string) => {
      const chartCard = document.getElementById("chartCard");
      const ellipsisMenu = document.querySelector(".ellipsis-menu");

      if (!chartCard || !ellipsisMenu) return;

      (ellipsisMenu as HTMLElement).style.display = "none"; // Hide the ellipsis menu

      const canvas = await html2canvas(chartCard, {
        backgroundColor: format === "jpg" ? "#FFFFFF" : null,
      });

      (ellipsisMenu as HTMLElement).style.display = ""; // Show the ellipsis menu again

      const dataURL = canvas.toDataURL(`image/${format}`);
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = `OrderDataChart.${format}`;
      link.click();
    };

    const exportChartAsIframe = () => {
      const iframeCode = `<iframe src="${window.location.href}" width="600" height="400" frameborder="0"></iframe>`;
      const textarea = document.createElement("textarea");
      textarea.value = iframeCode;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert("Iframe embed code copied to clipboard!");
    };

    watch([() => props.startDate, () => props.endDate], () => {
      console.log("Dates changed in OrderDataBarChart:", props.startDate, props.endDate);
      fetchChartData();
    });

    watch(selectedPeriod, fetchChartData);

    fetchChartData();

    return {
      series,
      chartOptions,
      selectedPeriod,
      exportChart,
      exportChartAsIframe,
      fetchChartData,
    };
  },
});
</script>

<style scoped>
.chart {
  width: 100%;
  height: 300px;
}

.ellipsis-menu {
  position: relative;
}

.ellipsis-menu .dropdown-menu {
  right: 0;
  left: auto;
}
</style>
