<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-product-box" :class="{'loading': isLoading}">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
        <div class="button-group mb-4">
          <button
            class="btn btn-primary"
            :class="{'active': importType === 'sku', 'btn-outline-primary': importType !== 'sku'}"
            @click="selectImportType('sku')"
          >
            SKU Data
          </button>
          <button
            class="btn btn-primary"
            :class="{'active': importType === 'order', 'btn-outline-primary': importType !== 'order'}"
            @click="selectImportType('order')"
          >
            Order Data
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Data Stream Table Name</label>
                <input
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.dataStreamTableName"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">Column Order</label>
                <input
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.columnOrder"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">CSV URI</label>
                <input
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="form.csvUri"
                />
              </div>
            </div>
            <div class="col-md-12">
              <FieldMapper :sourceFields="sourceFields" :targetFields="targetFields" @columnOrderGenerated="setColumnOrder" @fileSelected="handleFileSelected" />
            </div>
          </div>
        </form>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import axios from "axios";
  import moment from "moment";
  import { BlobServiceClient } from "@azure/storage-blob";
  import FieldMapper from "@/components/ELSA/DataImport/FieldMapper.vue";
  import { useRoute, useRouter } from "vue-router";
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  
  export default defineComponent({
    name: "ImportCsvForm",
    components: {
      FieldMapper,
    },
    props: {
      dataStreamID: {
        type: Number,
        required: true,
      },
      leadID: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const form = ref({
        dataStreamTableName: "Order Data - Standard",
        columnOrder: "",
        csvUri: "",
        mySQLtableName: "",
        customerName: "",
        enquiryID: null,
      });
  
      const isLoading = ref(false);
      const sourceFields = ref([]); // Placeholder for source fields, update this with actual source fields from the CSV
      const targetFieldsSKU = [
        'IDPrimary', 'SKUID', 'ItemDescription', 'UnitQuantity', 'UnitsInStock',
        'InnersInStock', 'InnersInOuter', 'OutersInPallet', 'UnitsSold_PeakMonth',
        'UnitsSold_Annually', 'UnitWidth', 'UnitDepth', 'UnitHeight', 'UnitWeight',
        'InnerWidth', 'InnerDepth', 'InnerHeight', 'InnerWeight', 'OuterWidth',
        'OuterDepth', 'OuterHeight', 'OuterWeight', 'ProductFamily', 'Miscellaneous1',
        'Miscellaneous2', 'Miscellaneous3', 'Miscellaneous4', 'Miscellaneous5', 'UnitDensity'
      ];
      const targetFieldsOrder = [
        'RunID', 'OrderID', 'SKUID', 'UnitQuantity', 'OrderDate',
        'DispatchDate', 'OrderType', 'Miscellaneous1', 'Miscellaneous2',
        'Miscellaneous3', 'Miscellaneous4', 'Miscellaneous5'
      ];
      const targetFields = ref(targetFieldsOrder); // Default to Order Data target fields
      const importType = ref('order'); // Default import type
  
      const route = useRoute();
      const router = useRouter();
  
      const handleFileSelected = async (file: File) => {
        const fileUri = await uploadToAzureBlob(file);
        form.value.csvUri = fileUri;
      };
  
      const uploadToAzureBlob = async (file: File): Promise<string> => {
        const accountName = "hsuiteblob";
        const sasToken = "sp=racwdli&st=2024-07-15T22:02:39Z&se=2025-07-16T06:02:39Z&spr=https&sv=2022-11-02&sr=c&sig=JNE1RXnzOfPmQ09%2B0qTcLnglkSRm3mbZgwyC3nAklEI%3D";
        const containerName = "elsadataimports";
        console.log(containerName)
        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(file.name);
  
        try {
          await blockBlobClient.uploadBrowserData(file);
          return blockBlobClient.url;
        } catch (error) {
          console.error("Error uploading file to Azure Blob Storage:", error);
          throw new Error("Failed to upload file to Azure Blob Storage.");
        }
      };
  
      const fetchProjectDetails = async () => {
        try {
          const response = await axios.get(`${BASE_HSUITE_URL}/api/Project/GetProjectByLeadID/${props.leadID}`);
          const projectDetails = response.data.projectDetails;
          form.value.customerName = projectDetails.clientName;
          form.value.enquiryID = projectDetails.enquiryID;
          generateMySQLTableName();
        } catch (error) {
          console.error("Error fetching project details:", error);
        }
      };
  
      const generateMySQLTableName = () => {
        const timestamp = moment().format("YYYYMMDDHHmmssSS");
        const customerNameShort = form.value.customerName.replace(/\s+/g, "").substring(0, 8).toUpperCase();
        const prefix = importType.value === 'sku' ? 'SKUData_' : 'ORDERData_';
        form.value.mySQLtableName = `${prefix}${customerNameShort}_${form.value.enquiryID}_WIP_${timestamp}`;
      };
  
      const setColumnOrder = (columnOrder) => {
        form.value.columnOrder = columnOrder;
      };
  
      const selectImportType = (type) => {
        importType.value = type;
        if (type === 'sku') {
          targetFields.value = targetFieldsSKU;
          form.value.dataStreamTableName = "SKU Data - Standard";
        } else if (type === 'order') {
          targetFields.value = targetFieldsOrder;
          form.value.dataStreamTableName = "Order Data - Standard";
        }
        generateMySQLTableName(); // Update table name prefix when import type changes
      };
  
      const submitForm = async () => {
        isLoading.value = true; // Show the loading overlay
        try {
          await fetchProjectDetails();
          const response = await axios.post(`${BASE_HSUITE_URL}/api/DataImport/ImportCsv`, {
            dataStreamID: props.dataStreamID,
            dataStreamTableName: form.value.dataStreamTableName,
            tableName: form.value.mySQLtableName,
            columnOrder: form.value.columnOrder,
            csvUri: form.value.csvUri,
          });
          alert(response.data.message);
          router.push({ name: "DataTableOverview", params: { leadID: props.leadID, dataStreamID: props.dataStreamID } });
        } catch (error) {
          console.error("Error importing CSV:", error);
        } finally {
          isLoading.value = false; // Hide the loading overlay
        }
      };
  
      return { form, sourceFields, targetFields, handleFileSelected, setColumnOrder, submitForm, selectImportType, importType, isLoading };
    },
  });
  </script>
  
  <style scoped>
  .field-mapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-section {
    margin-bottom: 20px;
  }
  
  .columns {
    display: flex;
    justify-content: center;
    width: 50%;
  }
  
  .table-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .source-column, .target-column {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  
  .source-column .field-item, .target-column .field-item {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .field-item {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 5px;
    box-sizing: border-box;
  }
  
  button {
    width: 100%;
    padding: 10px;
  }
  
  .target-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Right-align the target fields */
  }
  
  .normal-size-button {
    width: auto; /* Reset the width for the generate button */
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .button-group .btn {
    flex: none;
    width: 100px;
  }
  
  .button-group .btn-outline-primary {
    background-color: white;
    border: 1px solid #007bff;
    color: #007bff;
  }
  
  .button-group .btn-primary.active {
    background-color: #007bff;
    color: white;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading {
    cursor: wait;
  }
  </style>
  