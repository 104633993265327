<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-product-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Shelf Width</label>
              <input
                type="number"
                class="form-control shadow-none rounded-0 text-black"
                v-model.number="form.shelfWidth"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Shelf Height</label>
              <input
                type="number"
                class="form-control shadow-none rounded-0 text-black"
                v-model.number="form.shelfHeight"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Shelf Quantity</label>
              <input
                type="number"
                class="form-control shadow-none rounded-0 text-black"
                v-model.number="form.shelfQuantity"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Clearance</label>
              <input
                type="number"
                class="form-control shadow-none rounded-0 text-black"
                v-model.number="form.clearance"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Containers</label>
              <div class="d-flex mb-2 fw-bold">
                <span class="me-10" style="flex: 1;">Width</span>
                <span class="me-10" style="flex: 1;">Height</span>
                <span class="me-10" style="flex: 1;">Proportion (%)</span>
              </div>
              <div
                v-for="(container, index) in form.containers"
                :key="index"
                class="d-flex mb-10"
              >
                <input
                  type="number"
                  v-model.number="container.width"
                  placeholder="Width"
                  class="form-control mr-10"
                  style="flex: 1;"
                />
                <input
                  type="number"
                  v-model.number="container.height"
                  placeholder="Height"
                  class="form-control mr-10"
                  style="flex: 1;"
                />
                <input
                  type="number"
                  step="0.01"
                  v-model.number="container.proportion"
                  placeholder="Proportion"
                  class="form-control mr-10"
                  style="flex: 1;"
                />
                <button
                  @click="removeContainer(index)"
                  type="button"
                  class="btn btn-danger"
                >
                  Remove
                </button>
              </div>
              <button
                @click="addContainer"
                type="button"
                class="btn btn-primary mt-10"
              >
                Add Container
              </button>
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <!-- Summary Table -->
      <div v-if="visualizationData" class="mt-25">
        <h5 class="text-black fw-semibold mb-10">Summary Table</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Container Type</th>
              <th>Width (mm)</th>
              <th>Height (mm)</th>
              <th>Total Quantity</th>
              <th>Target Proportion</th>
              <th>Achieved Proportion</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(summary, index) in sortedContainerSummary" :key="index">
              <td>Container {{ summary.containerId }}</td>
              <td>{{ summary.width }}</td>
              <td>{{ summary.height }}</td>
              <td>{{ summary.total }}</td>
              <td>{{ (summary.targetProportion * 100).toFixed(2) }}%</td>
              <td>{{ (summary.achievedProportion * 100).toFixed(2) }}%</td>
            </tr>
            <tr>
              <td colspan="3"><strong>Total Containers</strong></td>
              <td><strong>{{ totalContainers }}</strong></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Shelf Visualization -->
      <div v-if="visualizationData" class="mt-25">
        <h5 class="text-black fw-semibold mb-10">Shelf Visualization</h5>
        <div v-for="(shelf, index) in visualizationData.shelves" :key="index" class="shelf-container position-relative">
          <!-- Wrapping label and visualization together for export -->
          <div :id="'shelf-visualization-' + index">
            <h6 class="text-black fw-medium mb-5">
              Shelf {{ shelf.shelf }} ({{ shelf.levels.reduce((sum, level) => sum + level.containers.length, 0) }} Containers)
            </h6>
            
            <div class="shelf-visualization">
              <div
                v-for="(level, levelIndex) in shelf.levels.slice().reverse()"
                :key="levelIndex"
                class="level-container"
              >
                <div class="level-label">
                  {{ getLevelHeight(shelf.levels, shelf.levels.length - levelIndex - 1) }} mm
                </div>
                <div class="level-visualization">
                  <div
                    v-for="(container, containerIndex) in level.containers"
                    :key="containerIndex"
                    :style="getContainerStyle(container)"
                    class="container-box"
                  >
                    ID: {{ container.containerId }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Export button slightly outside the frame -->
          <button 
            @click="exportShelfAsImage(index)" 
            class="btn btn-primary btn-sm position-absolute" 
            style="top: -10px; right: -10px;"
          >
            Export as JPG
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import axios from "axios";
import { BASE_HSUITE_URL } from '@/core/constant/env';
import html2canvas from "html2canvas";

interface Container {
  containerId: number;
  width: number;
  height: number;
  proportion?: number;
}

interface Level {
  containers: Container[];
}

interface Shelf {
  shelf: number;
  levels: Level[];
}

interface VisualizationData {
  proportions: { containerId: number; targetProportion: number; achievedProportion: number }[];
  shelves: Shelf[];
}

export default defineComponent({
  name: "ShelfOptimizer",
  setup() {
    const form = ref({
      shelfWidth: 2350,
      shelfHeight: 1800,
      shelfQuantity: 1,
      clearance: 50,
      containers: [
        { width: 105, height: 75, proportion: 0 },
        { width: 140, height: 130, proportion: 0 },
        { width: 210, height: 180, proportion: 0 },
        { width: 420, height: 180, proportion: 0 },
        { width: 210, height: 230, proportion: 0 },
        { width: 420, height: 295, proportion: 0 },
        { width: 400, height: 400, proportion: 0 },
        { width: 600, height: 600, proportion: 0 }
      ]
    });

    const visualizationData = ref<VisualizationData | null>(null);

    const addContainer = () => {
      form.value.containers.push({ width: 0, height: 0, proportion: 0 });
    };

    const removeContainer = (index: number) => {
      form.value.containers.splice(index, 1);
    };

    const submitForm = async () => {
      const adjustedForm = {
        ...form.value,
        containers: form.value.containers.map((container, index) => ({
          ...container,
          height: container.height + form.value.clearance,
          proportion: container.proportion / 100,
          containerId: index + 1
        }))
      };

      try {
        console.log(adjustedForm)
        const response = await axios.post(`${BASE_HSUITE_URL}/api/OOCAnalysis/OptimizeShelfDesign`, adjustedForm);
        console.log(response)

        response.data.shelves.forEach((shelf: Shelf) => {
          shelf.levels.forEach(level => {
            level.containers.forEach(container => {
              const originalContainer = form.value.containers[container.containerId - 1];
              if (originalContainer) {
                container.width = originalContainer.width;
                container.height = originalContainer.height;
              }
            });
          });
        });

        visualizationData.value = response.data;
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    const getLevelHeight = (levels, currentIndex) => {
      let cumulativeHeight = 0;

      for (let i = 0; i <= currentIndex; i++) {
        if (i !== 0) {
          const maxHeightCurrentLevel = Math.max(...levels[i-1].containers.map(container => container.height));
          cumulativeHeight += maxHeightCurrentLevel + form.value.clearance;
        }
      }

      return cumulativeHeight;
    };

    const exportShelfAsImage = async (shelfIndex: number) => {
      const element = document.getElementById(`shelf-visualization-${shelfIndex}`);
      if (element) {
        try {
          const canvas = await html2canvas(element);
          const dataURL = canvas.toDataURL("image/jpeg");
          const link = document.createElement('a');
          link.href = dataURL;
          link.download = `shelf-${shelfIndex + 1}.jpg`;
          link.click();
        } catch (error) {
          console.error("Error exporting image:", error);
        }
      }
    };

    const getContainerStyle = (container: any) => {
      const shelfWidthPx = 1000;
      const widthScaleFactor = shelfWidthPx / form.value.shelfWidth;
      const heightScaleFactor = widthScaleFactor;

      const width = container.width * widthScaleFactor;
      const height = container.height * heightScaleFactor;

      return {
        width: `${width}px`,
        height: `${height}px`,
        border: "1px solid #000",
        marginBottom: "2px",
        backgroundColor: getColorById(container.containerId),
        textAlign: "center" as const,
        lineHeight: `${height}px`
      };
    };

    const getColorById = (id) => {
      const colors = ["#ff9999", "#99ccff", "#99ff99", "#ffcc99", "#cc99ff"];
      return colors[(id - 1) % colors.length];
    };

    const calculateContainerSummary = () => {
      const summary = form.value.containers.map((container, index) => ({
        containerId: index + 1,
        width: container.width,
        height: container.height,
        total: 0,
        targetProportion: container.proportion / 100,
        achievedProportion: 0
      }));

      if (visualizationData.value) {
        const totalContainers = visualizationData.value.shelves.reduce((sum, shelf) => 
          sum + shelf.levels.reduce((levelSum, level) => levelSum + level.containers.length, 0), 0);

        visualizationData.value.shelves.forEach(shelf => {
          shelf.levels.forEach(level => {
            level.containers.forEach(container => {
              const index = container.containerId - 1;
              summary[index].total++;
              summary[index].achievedProportion = summary[index].total / totalContainers;
            });
          });
        });
      }

      return summary.sort((a, b) => a.containerId - b.containerId);
    };

    const sortedContainerSummary = computed(() => calculateContainerSummary());

    const totalContainers = computed(() => {
      return sortedContainerSummary.value.reduce((sum, { total }) => sum + total, 0);
    });

    return { 
      form, 
      addContainer, 
      removeContainer, 
      submitForm, 
      visualizationData, 
      getContainerStyle, 
      getLevelHeight,
      exportShelfAsImage,
      sortedContainerSummary,
      totalContainers
    };
  }
});
</script>


<style scoped>
.shelf-container {
  margin-bottom: 20px;
}

.shelf-visualization {
  display: flex;
  flex-direction: column; /* Levels are displayed from top to bottom */
  align-items: flex-start;
  border: 2px solid #000;
  padding: 10px;
  position: relative;
  width: 100%; /* Shelf visualization takes full width */
  box-sizing: border-box;
}

.level-container {
  display: flex;
  align-items: flex-end; /* Align boxes to the bottom */
  margin-bottom: 5px;
  width: 100%; /* Ensure level takes full width */
}

.level-label {
  width: 100px; /* Widened to fit the cumulative height */
  text-align: right;
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.level-visualization {
  display: flex;
  align-items: flex-end; /* Align the boxes to the bottom */
  border-bottom: 2px solid #000; /* Represent the shelf beam */
  padding-bottom: 5px;
  margin-bottom: 5px;
  height: 100%; /* Ensure height is scaled proportionally */
  box-sizing: border-box;
}

.container-box {
  background-color: #ccc;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Include padding and border in the dimensions */
  margin-right: 5px;
}
</style>
