import { ref, provide, inject, Ref, watch } from 'vue';

const hiddenSeriesSymbol = Symbol();

export function provideHiddenSeries() {
  const hiddenSeries = ref<number[]>([]);
  
  // Add a watch to log changes to hiddenSeries
  // watch(hiddenSeries, (newVal) => {
  //  console.log('provideHiddenSeries - hiddenSeries changed:', newVal);
  //}, { deep: true });

  provide(hiddenSeriesSymbol, hiddenSeries);
  console.log('provideHiddenSeries - hiddenSeries provided:', hiddenSeries.value);
  return hiddenSeries;
}

export function useHiddenSeries() {
  const hiddenSeries = inject<Ref<number[]>>(hiddenSeriesSymbol);
  if (!hiddenSeries) {
    throw new Error('useHiddenSeries must be used after provideHiddenSeries');
  }

  // Add a watch to log the initial value and subsequent changes to hiddenSeries
  // watch(hiddenSeries, (newVal) => {
  //  console.log('useHiddenSeries - hiddenSeries changed:', newVal);
  // }, { deep: true });

  console.log('useHiddenSeries - hiddenSeries injected:', hiddenSeries.value);
  return hiddenSeries;
}
