<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-header bg-white p-3">
      <h5 class="mb-0">Data Tables</h5>
    </div>
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="handleSearch">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search Data Table" v-model="searchQuery" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            New Analysis
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click.prevent="navigateToCreateRetrievalAnalysis">Create Retrieval Analysis</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="navigateToCreateOOCAnalysis">Create Outbound Order Container Analysis</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="navigateToOptimiseShelfDesign">Optimise Shelf Design</a></li> <!-- New option added here -->
          </ul>
        </div>
        <div class="dropdown ms-3">
          <button class="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Add Data Table
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click.prevent="openCreateLightbox">Add Data Table</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="navigateToImportOrderData">Import Raw Data</a></li>
          </ul>
        </div>
        <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchDataTables(searchQuery)">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0" style="min-height: 300px;">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0 name-column">
                NAME
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 description-column">
                DESCRIPTION
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 table-id-column">
                TABLE ID
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                CREATED AT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                UPDATED AT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dataTable in filteredDataTables" :key="dataTable.id">
              <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0 name-text" :title="dataTable.name">
                {{ dataTable.name }}
              </th>
              <td class="shadow-none lh-1 description-text" :title="dataTable.description">
                {{ dataTable.description }}
              </td>
              <td class="shadow-none lh-1 table-id-text" :title="dataTable.tableID">
                {{ dataTable.tableID }}
              </td>
              <td class="shadow-none lh-1 fw-medium" :title="formatDateTime(dataTable.createdAt)">
                {{ formatDateTime(dataTable.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium" :title="formatDateTime(dataTable.updatedAt)">
                {{ formatDateTime(dataTable.updatedAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-end pe-0">
                <div class="dropdown">
                  <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li v-if="isSKUData(dataTable.tableID)">
                      <router-link :to="getRoute('SKUDataTablePage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View SKU Data
                      </router-link>
                    </li>
                    <li v-if="isSKUData(dataTable.tableID)">
                      <router-link :to="getRoute('SKUBandingResultsPage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View SKU Banding Results
                      </router-link>
                    </li>
                    <li v-if="isSKUData(dataTable.tableID)">
                      <router-link :to="getRoute('SuggestedSystemAnalysisPage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        Suggested System Analysis
                      </router-link>
                    </li>
                    <li v-if="isSKUData(dataTable.tableID)">
                      <a @click.prevent="viewRawDataTables(dataTable.tableID)" class="dropdown-item d-flex align-items-center" href="#">
                        <i class="flaticon-table lh-1 me-8 position-relative top-1"></i>
                        View Raw Data Tables
                      </a>
                    </li>
                    <li v-if="isRESULTSTriSys(dataTable.tableID)">
                      <router-link :to="getRoute('TripleSystemAnalysisPage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View Results TriSys
                      </router-link>
                    </li>
                    <li v-if="isWaveAggSim(dataTable.tableID)">
                      <router-link :to="getRoute('RetrievalAnalysisResultsPage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View Results
                      </router-link>
                    </li>
                    <li v-if="isOrderData(dataTable.tableID)">
                      <router-link :to="getRoute('OrderDataStatsPage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        Results Analysis
                      </router-link>
                    </li>
                    <li v-if="isOOCAnalysis(dataTable.tableID)">
                      <router-link :to="getRoute('OOCAnalysisResultsPage', dataTable)" class="dropdown-item d-flex align-items-center">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View OOC Analysis Results
                      </router-link>
                    </li>

                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="openUpdateLightbox(dataTable)">
                        <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
                        Edit
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="confirmDelete(dataTable.id)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <!-- Placeholder rows to maintain table structure and height -->
            <template v-if="filteredDataTables.length < 5">
              <tr v-for="i in 5 - filteredDataTables.length" :key="'placeholder-' + i" class="table-row placeholder-row">
                <td colspan="6">&nbsp;</td>
              </tr>
            </template>
            <template v-if="filteredDataTables.length === 0">
              <tr>
                <td colspan="6" class="text-center">No data available</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ filteredDataTables.length }}</span> out of
          <span class="fw-bold">{{ totalDataTables }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item" :class="{ disabled: pageIndex === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
              <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <Lightbox v-if="showCreateLightbox" @close="closeCreateLightbox">
      <CreateDataTable :dataStreamID="dataStreamID" @dataTableAdded="handleDataTableAdded" @close="closeCreateLightbox"/>
    </Lightbox>
    <Lightbox v-if="showUpdateLightbox" @close="closeUpdateLightbox">
      <UpdateDataTable :id="selectedDataTable.id" :dataStreamID="dataStreamID" :initialTableID="selectedDataTable.tableID" :initialName="selectedDataTable.name" :initialDescription="selectedDataTable.description" @dataTableUpdated="handleDataTableUpdated" @close="closeUpdateLightbox"/>
    </Lightbox>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import Lightbox from '@/components/Common/LightboxWrapper.vue';
import CreateDataTable from '@/components/ELSA/TableManagement/AddDataTable.vue';
import UpdateDataTable from '@/components/ELSA/TableManagement/UpdateDataTable.vue';
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "DataTableList",
  components: {
    Lightbox,
    CreateDataTable,
    UpdateDataTable
  },
  props: {
    dataStreamID: {
      type: Number,
      required: true
    },
    leadID: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const showCreateLightbox = ref(false);
    const showUpdateLightbox = ref(false);
    const selectedDataTable = ref({});
    const dataTables = ref([]);
    const searchQuery = ref("");
    const filteredDataTables = ref([]);
    const pageSize = ref(25);
    const pageIndex = ref(1);
    const totalDataTables = ref(0);
    const totalPages = ref(1);

    const router = useRouter();

    const fetchDataTables = async (filter = "") => {
      console.log("Fetching data tables for dataStreamID:", props.dataStreamID);  // Debug log
      try {
        const response = await axios.get(`${BASE_HSUITE_URL}/api/DataTable/GetFilteredDataTables`, {
          params: {
            LeadID: props.dataStreamID,
            name: filter || undefined,
            pageSize: pageSize.value,
            pageIndex: pageIndex.value
          }
        });
        console.log("API Response:", response.data);  // Debug log
        if (response.data.status === "Success") {
          dataTables.value = response.data.dataTables;
          filteredDataTables.value = response.data.dataTables;
          totalDataTables.value = response.data.totalCount;
          totalPages.value = Math.ceil(totalDataTables.value / pageSize.value);
        }
      } catch (error) {
        console.error('Error fetching DataTables:', error);
      }
    };

    

    const handleSearch = (event) => {
      event.preventDefault();
      const filter = searchQuery.value.trim();
      pageIndex.value = 1; // Reset to first page on search
      fetchDataTables(filter);
    };

    const navigateToOptimiseShelfDesign = () => {
  router.push({
    name: 'OptimiseShelfDesign',
    params: {
      leadID: props.leadID,
      dataStreamID: props.dataStreamID
    }
  });
};


    const changePage = (page) => {
      if (page > 0 && page <= totalPages.value) {
        pageIndex.value = page;
        fetchDataTables(searchQuery.value);
      }
    };

    const confirmDelete = async (id) => {
      if (confirm('Are you sure you want to delete this data table? This action cannot be undone.')) {
        try {
          await axios.delete(`${BASE_HSUITE_URL}/api/DataTable/DeleteDataTable/${id}`);
          fetchDataTables(searchQuery.value); // Refresh the list after deletion
        } catch (error) {
          console.error('Error deleting DataTable:', error);
        }
      }
    };

    const openCreateLightbox = () => {
      showCreateLightbox.value = true;
    };

    const closeCreateLightbox = () => {
      showCreateLightbox.value = false;
    };

    const openUpdateLightbox = (dataTable) => {
      selectedDataTable.value = dataTable;
      showUpdateLightbox.value = true;
    };

    const closeUpdateLightbox = () => {
      showUpdateLightbox.value = false;
    };

    const handleDataTableAdded = () => {
      closeCreateLightbox();
      fetchDataTables(searchQuery.value);
    };

    const handleDataTableUpdated = () => {
      closeUpdateLightbox();
      fetchDataTables(searchQuery.value);
    };

    const isSKUData = (tableID) => {
      return tableID.split('_')[0] === 'SKUData';
    };

    const isRESULTSTriSys = (tableID) => {
      return tableID.split('_')[0] === 'RESULTSTriSys';
    };

    const isWaveAggSim = (tableID) => {
      return tableID.startsWith('WaveAggSim');
    };

    const isOrderData = (tableID) => {
      return tableID.startsWith('ORDERData') || tableID.startsWith('PDORDERData');
    };

    const isOOCAnalysis = (tableID) => {
      return tableID.startsWith('OOCAnalysis');
    };

    const getRoute = (routeName, dataTable) => {
      return {
        name: routeName,
        params: {
          leadID: props.leadID,
          dataStreamID: props.dataStreamID,
          tableName: dataTable.tableID,
        },
      };
    };

    const navigateToCreateRetrievalAnalysis = () => {
      router.push({
        name: 'CreateRetrievalAnalysis',
        params: {
          leadID: props.leadID,
          dataStreamID: props.dataStreamID
        }
      });
    };

    const navigateToCreateOOCAnalysis = () => {
      router.push({
        name: 'CreateOOCAnalysis',
        params: {
          leadID: props.leadID,
          dataStreamID: props.dataStreamID
        }
      });
    };

    const navigateToImportOrderData = () => {
      router.push({
        name: 'ImportCsvDataPage',
        params: {
          leadID: props.leadID,
          dataStreamID: props.dataStreamID
        }
      });
    };

    const viewRawDataTables = async (tableID) => {
      try {
        const response = await axios.get(`${BASE_HSUITE_URL}/api/Project/GetProjectByLeadID/${props.leadID}`);
        const enquiryID = response.data.projectDetails.enquiryID;
        console.log('Enquiry ID:', enquiryID); // Debug log
        router.push({
          name: 'SKUDataTableListPage',
          params: {
            leadID: props.leadID,
            dataStreamID: props.dataStreamID,
            enquiryID: enquiryID
          }
        });
      } catch (error) {
        console.error('Error fetching enquiry ID:', error);
      }
    };

    const formatDateTime = (date) => {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      return new Date(date).toLocaleDateString('en-GB', options).replace(',', '');
    };

    onMounted(() => {
      if (props.dataStreamID) {
        fetchDataTables();
      }
    });

    watch(() => props.dataStreamID, (newDataStreamID) => {
      if (newDataStreamID) {
        fetchDataTables();
      }
    });

    return {
      showCreateLightbox,
      showUpdateLightbox,
      selectedDataTable,
      dataTables,
      searchQuery,
      filteredDataTables,
      pageSize,
      pageIndex,
      totalDataTables,
      totalPages,
      fetchDataTables,
      handleSearch,
      changePage,
      confirmDelete,
      openCreateLightbox,
      closeCreateLightbox,
      openUpdateLightbox,
      closeUpdateLightbox,
      handleDataTableAdded,
      handleDataTableUpdated,
      isSKUData,
      isRESULTSTriSys,
      isWaveAggSim,
      isOrderData,
      isOOCAnalysis,
      getRoute,
      navigateToCreateRetrievalAnalysis,
      navigateToCreateOOCAnalysis,
      navigateToOptimiseShelfDesign,
      navigateToImportOrderData,
      viewRawDataTables,
      formatDateTime
    };
  }
});
</script>

<style scoped>
.table-responsive {
  min-height: 300px; /* Ensures table has a minimum height */
}

.table tbody tr.table-row {
  max-height: 20px; /* Ensures each row has a maximum height */
}

.table tbody tr.placeholder-row {
  height: 20px; /* Placeholder rows to maintain table structure and height */
}

.table tbody tr.placeholder-row td {
  background: transparent; /* Makes the placeholder rows transparent */
}

.description-text {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15%; /* Set a maximum width to ensure ellipsis */
  /*max-width: 300px; /* Adjust the width as needed */
}

.name-text {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Set a maximum width to ensure ellipsis */
}

.table-id-text {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 310px; /* Adjust the width as needed */
}

.name-column {
  min-width: 250px; /* Set a maximum width to ensure ellipsis */
  max-width: 15%; /* Set a maximum width to ensure ellipsis */
}
</style>
