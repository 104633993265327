<template>
    <div class="update-data-table">
      <h2>Update Data Table</h2>
      <div class="form-group mb-4">
        <label for="tableID" class="form-label">Table ID</label>
        <input
          type="text"
          id="tableID"
          class="form-control"
          v-model="tableID"
          placeholder="Enter table ID"
        />
      </div>
      <div class="form-group mb-4">
        <label for="name" class="form-label">Name</label>
        <input
          type="text"
          id="name"
          class="form-control"
          v-model="name"
          placeholder="Enter data table name"
        />
      </div>
      <div class="form-group mb-4">
        <label for="description" class="form-label">Description</label>
        <textarea
          id="description"
          class="form-control"
          v-model="description"
          placeholder="Enter data table description"
          rows="4"
        ></textarea>
      </div>
      <div class="form-buttons">
        <button class="btn btn-primary" @click="updateDataTable">Update Data Table</button>
        <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import axios from 'axios';
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  
  export default defineComponent({
    name: 'UpdateDataTable',
    props: {
      id: {
        type: Number,
        required: true
      },
      dataStreamID: {
        type: Number,
        required: true
      },
      initialTableID: {
        type: String,
        required: true
      },
      initialName: {
        type: String,
        required: true
      },
      initialDescription: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const tableID = ref(props.initialTableID);
      const name = ref(props.initialName);
      const description = ref(props.initialDescription);
  
      const updateDataTable = async () => {
        try {
          const response = await axios.put(`${BASE_HSUITE_URL}/api/DataTable/UpdateDataTable`, {
            Id: props.id,
            DataStreamID: props.dataStreamID,
            TableID: tableID.value,
            Name: name.value,
            Description: description.value
          });
  
          if (response.data.status === 'Success') {
            alert('Data table updated successfully!');
            emit('dataTableUpdated'); // Emit the event to notify the parent component
            emit('close'); // Close the lightbox after updating data table
          } else {
            console.error('Error response:', response.data);
            alert('Failed to update data table.');
          }
        } catch (error) {
          console.error('Error updating data table:', error);
          alert('Error updating data table.');
        }
      };
  
      return {
        tableID,
        name,
        description,
        updateDataTable
      };
    }
  });
  </script>
  
  <style scoped>
  .update-data-table {
    width: 500px; /* Double the size */
    height: 450px; /* Double the size */
    padding: 40px; /* Increase padding */
    background-color: #fff; /* Ensure background is white */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .btn {
    padding: 0.5rem 1rem;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  </style>
  