<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
<div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
  <form>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-15 mb-sm-20 mb-md-25">
          <label class="d-block text-black fw-semibold mb-10"> Password </label>
          <input type="password" class="form-control shadow-none rounded-0 text-black" v-model="password">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-15 mb-sm-20 mb-md-25">
          <label class="d-block text-black fw-semibold mb-10"> Confirm Password </label>
          <input type="password" class="form-control shadow-none rounded-0 text-black" v-model="confirmPassword">
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex align-items-center">
          <button @click="SaveData" class="default-btn transition border-0 fw-medium text-white 
          pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
          fs-md-15 fs-lg-16 m-5 bg-success" type="button"> Save </button>
          <button @click="GoToMainMenu" class="default-btn transition border-0 fw-medium text-white 
          pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
          fs-md-15 fs-lg-16 m-5 bg-danger" type="button"> Cancel </button>
        </div>
      </div>
    </div>
  </form>
</div>
</div>
</template>
<script>

import { ref } from 'vue';
import { ResetPassword } from '@/core/services/entities/authentication.service'
import { useToast } from 'vue-toastification'
import { useRoute, useRouter } from "vue-router";

export default {
  name: "ResetPassword",
  setup(){
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const password=ref('');
    const confirmPassword=ref('');
    const passwordError = ref('');

    const GoToMainMenu=async()=>{
      router.push({ name: "UsersListPage" });
    }

    const SaveData=async()=>{
      if(password.value!=confirmPassword.value){

        toast.error('Passwords do not match. Please try again.', {
          timeout: 2000,
        });

        return false; 
      }

      if(!validatePassword())
        return;

      try{

          var response = await ResetPassword({email: route.params.email, password: password.value});
          if(response.status == 200 || response.status == 201)
          {
            toast.success('Password has been successfully reset.', {timeout: 2000,})
            GoToMainMenu();
            
          }
          else
          {
              toast.error('Failed to reset password', {timeout: 2000,});
          }
      }catch(error){
        toast.error(error.response.data, {timeout: 5000,});
      } 

      
    }

    const validatePassword = () => {
      const minLength = 8;
      const passwordRegex = {
        length: new RegExp(`^.{${minLength},}$`),
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        number: /\d/,
        specialChar: /[!@#$%^&*(),.?":{}|<>]/
      };

      passwordError.value = [];

      if (!passwordRegex.length.test(password.value)) {
        passwordError.value.push(`Password must be at least ${minLength} characters long.`);
      }
      if (!passwordRegex.uppercase.test(password.value)) {
        passwordError.value.push('Password must contain at least one uppercase letter.');
      }
      if (!passwordRegex.lowercase.test(password.value)) {
        passwordError.value.push('Password must contain at least one lowercase letter.');
      }
      if (!passwordRegex.number.test(password.value)) {
        passwordError.value.push('Password must contain at least one number.');
      }
      if (!passwordRegex.specialChar.test(password.value)) {
        passwordError.value.push('Password must contain at least one special character.');
      }

      if (passwordError.value.length === 0) {
        return true;
      } else {
        const errorMessage = passwordError.value.map((error, index) => `${index + 1}. ${error}`).join('\n');
        toast.error(errorMessage, {
          timeout: 4000,
        });
        return false;
      }
    };

    return {
      password,
      confirmPassword,
      GoToMainMenu,
      SaveData,
      validatePassword
    };
  }

}
</script>