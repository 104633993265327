<template>
  <div>
    <div class="filter-section mb-3">
      <form @submit.prevent="fetchWorkOrderRequests">
        <div class="row">
          <div class="col">
            <label for="requesterID">Requester ID</label>
            <input v-model="filters.requesterID" id="requesterID" type="text" placeholder="Requester ID" class="form-control">
          </div>
          <div class="col">
            <label for="departmentID">Department</label>
            <select v-model="filters.departmentID" id="departmentID" class="form-control">
              <option v-for="department in departments" :key="department.departmentID" :value="department.departmentID">
                {{ department.departmentName }}
              </option>
            </select>
          </div>
          <div class="col">
            <button type="submit" class="btn btn-primary mt-4">Filter</button>
          </div>
        </div>
      </form>
    </div>

    <div class="row">
      <div v-for="(column, status) in columns" :key="status" class="col-lg-2 col-md-4 col-sm-6">
        <div :class="['card', 'mb-25', 'border-0', 'rounded-0', 'bg-white', 'kanban-title-card', columnColor(status)]">
          <div class="card-body p-15 p-sm-20 letter-spacing">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="card-title fw-bold mb-0 fs-lg-18">{{ status }} <span class="text-muted">({{ column.length }})</span></h5>
            </div>
          </div>
        </div>

        <div v-for="request in column" :key="request.workOrderRequestID" :class="['card', 'mb-25', 'border-0', 'rounded-0', 'bg-white', 'kanban-card']">
          <div class="card-body p-15 p-sm-20 letter-spacing">
            <div class="mb-10 d-flex align-items-center justify-content-between">
              <span class="d-block fs-md-15 fw-bold text-black">{{ request.clientName }} - {{ request.requestTypeName }}</span>
              <button @click="toggleCollapse(request.workOrderRequestID)" class="btn btn-link p-0">
                <i :class="[isCollapsed(request.workOrderRequestID) ? 'ph ph-caret-down' : 'ph ph-caret-up']"></i>
              </button>
            </div>
            <transition name="slide-fade">
              <div v-show="!isCollapsed(request.workOrderRequestID)" class="collapsible-content">
                <p class="text-paragraph lh-base fs-md-15">
                  ProjectID: {{ request.projectID }}
                </p>
                <p class="text-paragraph lh-base fs-md-15">
                  Description: {{ request.requestTypeDescription }}
                </p>
                <p class="text-paragraph lh-base fs-md-15">
                  Salesperson: {{ request.salesman }}
                </p>
              </div>
            </transition>
            <div class="mt-15 info d-flex align-items-center justify-content-between">
              <span class="d-block text-danger position-relative">
                <i class="ph ph-clock"></i> {{ calculateDueDays(request.dueDate) }}d due
              </span>
            </div>
          </div>
        </div>

        <a v-if="status === 'Pending'" href="#" class="add-another-task-btn mb-25 letter-spacing d-block w-100 fs-md-15 fw-medium text-center text-decoration-none position-relative bg-white transition text-success" data-bs-toggle="modal" data-bs-target="#addAnotherTaskModal">
          Add Another Task <i class="ph-bold ph-plus"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default {
  name: 'KanbanContent',
  data() {
    return {
      workOrderRequests: [],
      departments: [],
      filters: {
        requesterID: '',
        departmentID: ''
      },
      columns: {
        Pending: [],
        'On Hold': [],
        'In Progress': [],
        'To Review': [],
        Complete: []
      },
      collapsedRequests: {}
    };
  },
  methods: {
    fetchWorkOrderRequests() {
      const params = { ...this.filters, pageSize: 10, pageNumber: 1 };
      axios.get(`${BASE_HSUITE_URL}/api/WorkOrderRequest/GetAllWorkOrderRequests`, { params })
        .then(response => {
          if (response.data.status === 'Success') {
            this.workOrderRequests = response.data.workOrderRequests;
            this.categorizeRequests();
            this.initializeCollapsedRequests();
          }
        })
        .catch(error => {
          console.error('Error fetching work order requests:', error);
        });
    },
    fetchDepartments() {
      axios.get(`${BASE_HSUITE_URL}/api/Department/GetAllDepartments`)
        .then(response => {
          if (response.data.status === 'Success') {
            this.departments = response.data.departments;
          }
        })
        .catch(error => {
          console.error('Error fetching departments:', error);
        });
    },
    initializeCollapsedRequests() {
      this.workOrderRequests.forEach(request => {
        this.$set(this.collapsedRequests, request.workOrderRequestID, true);
      });
    },
    categorizeRequests() {
      this.columns.Pending = [];
      this.columns['On Hold'] = [];
      this.columns['In Progress'] = [];
      this.columns['To Review'] = [];
      this.columns.Complete = [];

      this.workOrderRequests.forEach(request => {
        if (request.status === 'Pending') this.columns.Pending.push(request);
        else if (request.status === 'On Hold') this.columns['On Hold'].push(request);
        else if (request.status === 'In Progress') this.columns['In Progress'].push(request);
        else if (request.status === 'To Review') this.columns['To Review'].push(request);
        else if (request.status === 'Complete') this.columns.Complete.push(request);
      });
    },
    calculateDueDays(dueDate) {
      const due = new Date(dueDate);
      const today = new Date();
      const diffTime = Math.abs(due - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    columnColor(status) {
      switch(status) {
        case 'Pending':
          return 'border-top-warning';
        case 'On Hold':
          return 'border-top-secondary';
        case 'In Progress':
          return 'border-top-primary';
        case 'To Review':
          return 'border-top-info';
        case 'Complete':
          return 'border-top-success';
        default:
          return 'border-top-light';
      }
    },
    toggleCollapse(requestID) {
      this.collapsedRequests[requestID] = !this.collapsedRequests[requestID];
    },
    isCollapsed(requestID) {
      return this.collapsedRequests[requestID] !== false; // default to true if undefined
    }
  },
  mounted() {
    this.fetchDepartments();
    this.fetchWorkOrderRequests();
  }
};
</script>

<style>
/* Add your styles here */
.kanban-title-card.border-top-warning {
  border-top: 5px solid #ffc107 !important;
}
.kanban-title-card.border-top-secondary {
  border-top: 5px solid #6c757d !important;
}
.kanban-title-card.border-top-primary {
  border-top: 5px solid #007bff !important;
}
.kanban-title-card.border-top-info {
  border-top: 5px solid #17a2b8 !important;
}
.kanban-title-card.border-top-success {
  border-top: 5px solid #28a745 !important;
}
.ph-caret-up::before {
  content: '\e93e'; /* Placeholder for caret-up icon */
}
.ph-caret-down::before {
  content: '\e93c'; /* Placeholder for caret-down icon */
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
.collapsible-content {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}
</style>
