import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e6e2f7ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_2 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_3 = { "aria-label": "breadcrumb" }
const _hoisted_4 = { class: "breadcrumb mb-0 list-unstyled ps-0" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.PageTitle), 1),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("ol", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "text-decoration-none text-black"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Home ")
            ]),
            _: 1
          })
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (crumb, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(["breadcrumb-item", { active: index === _ctx.breadcrumbs.length - 1 }]),
            "aria-current": "page"
          }, [
            (index !== _ctx.breadcrumbs.length - 1)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: crumb.path,
                  class: "text-decoration-none text-black"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(crumb.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(crumb.name), 1))
          ], 2))
        }), 128))
      ])
    ])
  ]))
}