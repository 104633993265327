<template>
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-md-10 d-flex align-items-center justify-content-between">
        <h6 class="card-title fw-bold mb-0">Unique SKUs Retrieved per day - with percentile analysis</h6>
      </div>
      <div class="row">
        <div class="col-lg-9 col-xxxl-10 col-md-10">
          <div id="skuVariabilityChart" class="chart">
            <apexchart
              type="bar"
              height="325"
              :options="chartOptions"
              :series="chartData"
            ></apexchart>
          </div>
        </div>
        <div class="col-lg-3 col-xxxl-2 col-md-2">
          <ul class="list ps-0 mb-0 mt-15 mt-md-25 list-unstyled">
            <li v-for="(label, index) in labels" :key="index">
              <div class="d-flex align-items-center mb-5">
                <span :class="['d-block w-10 h-10 rounded-circle', label.colorClass]"></span>
                <span class="fs-12 fw-medium text-black-emphasis text-uppercase lh-1 ms-8">
                  {{ label.name }} ({{ label.equation }})
                </span>
              </div>
              <h4 class="mb-0 fw-black">{{ label.minValue }}</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, watch, nextTick } from 'vue';
  import axios from 'axios';
  import ApexCharts from 'apexcharts';
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  
  export default defineComponent({
    name: 'RetrievalSimulationPercentileAnalysis',
    props: {
      tableName: {
        type: String,
        required: true,
      },
      startDate: {
        type: String,
        required: true,
      },
      endDate: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const chartData = ref([{ name: 'Unique SKUs', data: [] }]);
      const chartOptions = ref({
        chart: {
          type: 'bar',
          height: 325,
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#FF6347', '#FFA500', '#FFD700', '#9ACD32', '#32CD32'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '25%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'Red Hat Display, sans-serif',
              colors: '#9C9AB6',
              fontSize: '14px',
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        yaxis: {
          show: true,
          tickAmount: 5,
          labels: {
            style: {
              fontFamily: 'Red Hat Display, sans-serif',
              colors: ['#9C9AB6'],
              fontSize: '14px',
              fontWeight: 500,
            },
          },
          axisBorder: {
            show: true,
            color: '#eeeeee',
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Red Hat Display, sans-serif',
          },
        },
        grid: {
          show: true,
          strokeDashArray: 5,
          borderColor: '#d9e9ef',
        },
      });
      const labels = ref([
        { name: 'Max', colorClass: 'bg-red', equation: '= Max', minValue: 0 },
        { name: '90th Percentile', colorClass: 'bg-orange', equation: '>= 90th', minValue: 0 },
        { name: '75th Percentile', colorClass: 'bg-yellow', equation: '>= 75th', minValue: 0 },
        { name: 'Median', colorClass: 'bg-yellowgreen', equation: '>= Median', minValue: 0 },
        { name: 'Min', colorClass: 'bg-green', equation: '> Min', minValue: 0 },
      ]);
  
      const fetchData = async () => {
        try {
          console.log('Fetching data with the following parameters:');
          console.log('TableName:', props.tableName);
          console.log('PageSize:', 1095);
          console.log('PageNumber:', 1);
          console.log('StartDate:', props.startDate);
          console.log('EndDate:', props.endDate);
  
          const response = await axios.post(
            `${BASE_HSUITE_URL}/api/RetrievalQuantityAnalysis/GetRQAAggregatedData`,
            {
              TableName: props.tableName,
              PageSize: 1095,
              PageNumber: 1,
              StartDate: props.startDate,
              EndDate: props.endDate,
            }
          );
  
          console.log('API response:', response.data);
  
          let uniqueSKUs = response.data.data.map((item: any) => item.uniqueSKUs);
          let waveDates = response.data.data.map((item: any) => new Date(item.waveDate).getTime());
  
          const max = Math.max(...uniqueSKUs);
          const p90 = calculatePercentile(uniqueSKUs, 0.9);
          const p75 = calculatePercentile(uniqueSKUs, 0.75);
          const median = calculatePercentile(uniqueSKUs, 0.5);
  
          // Assign min values to labels
          labels.value[0].minValue = uniqueSKUs.filter(value => value >= max).reduce((min, val) => val < min ? val : min, Infinity);
          labels.value[1].minValue = uniqueSKUs.filter(value => value >= p90 && value < max).reduce((min, val) => val < min ? val : min, Infinity);
          labels.value[2].minValue = uniqueSKUs.filter(value => value >= p75 && value < p90).reduce((min, val) => val < min ? val : min, Infinity);
          labels.value[3].minValue = uniqueSKUs.filter(value => value >= median && value < p75).reduce((min, val) => val < min ? val : min, Infinity);
          labels.value[4].minValue = uniqueSKUs.filter(value => value < median).reduce((min, val) => val < min ? val : min, Infinity);
  
          // Combine data for sorting and processing
          let combinedData = uniqueSKUs.map((value, index) => ({
            uniqueSKUs: value,
            waveDate: waveDates[index],
            fillColor: value >= max
              ? '#FF6347'
              : value >= p90
                ? '#FFA500'
                : value >= p75
                  ? '#FFD700'
                  : value >= median
                    ? '#9ACD32'
                    : '#32CD32',
          }));
  
          // Sort by uniqueSKUs descending
          combinedData.sort((a, b) => b.uniqueSKUs - a.uniqueSKUs);
  
          // Separate sorted data
          uniqueSKUs = combinedData.map(item => item.uniqueSKUs);
          waveDates = combinedData.map(item => item.waveDate);
  
          chartData.value = [{
            name: 'Unique SKUs',
            data: uniqueSKUs.map((value, index) => ({
              x: waveDates[index],
              y: value,
              fillColor: combinedData[index].fillColor,
            }))
          }];
  
          nextTick(() => {
            ApexCharts.exec('skuVariabilityChart', 'updateOptions', chartOptions.value);
            ApexCharts.exec('skuVariabilityChart', 'updateSeries', chartData.value);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const calculatePercentile = (arr: number[], percentile: number) => {
        const sorted = [...arr].sort((a, b) => a - b);
        const index = Math.floor(percentile * sorted.length);
        return sorted[index];
      };
  
      watch([() => props.tableName, () => props.startDate, () => props.endDate], fetchData, { immediate: true });
  
      return {
        chartData,
        chartOptions,
        labels,
      };
    },
  });
  </script>
  
  <style scoped>
  .date-picker {
    margin-right: 10px;
    padding: 10px;
    font-size: 16px;
  }
  
  .bg-red {
    background-color: #FF6347;
  }
  
  .bg-orange {
    background-color: #FFA500;
  }
  
  .bg-yellow {
    background-color: #FFD700;
  }
  
  .bg-yellowgreen {
    background-color: #9ACD32;
  }
  
  .bg-green {
    background-color: #32CD32;
  }
  </style>
  