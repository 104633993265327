<template>
    <BreadCrumb PageTitle="User Layout" />
    <UserLayout />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import UserLayout from "../../components/UserLayouts/UserLayout.vue";
  
  export default defineComponent({
    name: "UserLayoutPage",
    components: {
      BreadCrumb,
      UserLayout,
    },
  });
  </script>