<template>
  <div class="field-mapper">
    <div class="upload-section">
      <h4>Upload CSV File</h4>
      <input type="file" @change="handleFileUpload" />
    </div>
    <div v-if="localSourceFields.length > 0" class="columns">
      <div class="table-container">
        <div class="source-column">
          <h4>Source Fields</h4>
          <div v-for="(field, index) in localSourceFields" :key="field" class="field-item">
            <button
              type="button"
              :class="getButtonClass(field, 'source')"
              @click="selectField(field, 'source', index)"
            >
              {{ field }}
            </button>
          </div>
        </div>
        <div class="target-column">
          <h4>Target Fields</h4>
          <div v-for="field in orderedTargetFields" :key="field.name" class="field-item">
            <button
              type="button"
              :class="getButtonClass(field.name, 'target')"
              @click="selectField(field.name, 'target')"
            >
              {{ field.name }}
            </button>
            <span v-if="field.order !== null">Order: {{ field.order }}</span>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="Object.keys(mappings).length > 0"
      @click="generateColumnOrder"
      class="btn btn-primary mt-3 normal-size-button"
    >
      Submit
    </button>
  </div>
</template>

<script>
export default {
  props: {
    sourceFields: {
      type: Array,
      required: true,
    },
    targetFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localSourceFields: [],
      localTargetFields: [],
      selectedSourceField: null,
      selectedSourceIndex: null,
      selectedTargetField: null,
      mappings: {},
    };
  },
  computed: {
    orderedTargetFields() {
      // Sort target fields based on their order
      return this.localTargetFields.slice().sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    getButtonClass(field, type) {
      if (type === 'source') {
        return this.mappings[field] ? 'btn btn-success' : 'btn btn-outline-danger';
      } else if (type === 'target') {
        return Object.values(this.mappings).includes(field) ? 'btn btn-success' : 'btn btn-outline-danger';
      }
    },
    selectField(field, type, index = null) {
      if (type === 'source') {
        this.selectedSourceField = field;
        this.selectedSourceIndex = index;
      } else if (type === 'target') {
        this.selectedTargetField = field;

        if (this.selectedSourceField !== null) {
          this.mappings[this.selectedSourceField] = field;
          const targetField = this.localTargetFields.find(f => f.name === field);
          if (targetField) {
            targetField.order = this.selectedSourceIndex;
            console.log(`Connected Source Field Index: ${this.selectedSourceIndex}, Target Field Order: ${targetField.order}`);
          }
          this.selectedSourceField = null;
          this.selectedSourceIndex = null;
          this.selectedTargetField = null;
          this.resetUnconnectedOrders();
          this.reorderTargetFields();
        }
      }
    },
    resetUnconnectedOrders() {
      this.localTargetFields.forEach(field => {
        if (!Object.values(this.mappings).includes(field.name)) {
          field.order = null;
        }
      });
    },
    reorderTargetFields() {
      const usedOrders = new Set();
      const unmappedFields = [];

      // Collect orders already used by mapped fields and unmapped fields
      this.localTargetFields.forEach(field => {
        if (field.order !== null) {
          usedOrders.add(field.order);
        } else {
          unmappedFields.push(field);
        }
      });

      // Sort unmapped fields based on the order they appear
      unmappedFields.sort((a, b) => a.order - b.order);

      // Fill the gaps for unmapped fields
      let nextOrder = 0;
      unmappedFields.forEach(field => {
        while (usedOrders.has(nextOrder)) {
          nextOrder++;
        }
        field.order = nextOrder;
        usedOrders.add(nextOrder);
        nextOrder++;
      });

      console.log('Reordered Target Fields:', this.localTargetFields);
    },
    generateColumnOrder() {
      const columnOrder = this.localSourceFields
        .map(sourceField => this.mappings[sourceField] || '')
        .filter(Boolean) // This will remove any empty strings (unmapped fields)
        .join(';');

      this.$emit('columnOrderGenerated', columnOrder);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.$emit('fileSelected', file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.extractHeaders(csvContent);
        };
        reader.readAsText(file);
      }
    },
    extractHeaders(csvContent) {
      const lines = csvContent.split('\n');
      if (lines.length > 0) {
        const headers = lines[0].split(',');
        this.localSourceFields = headers.map(header => header.trim());
        this.localTargetFields = this.targetFields.map(field => ({ name: field, order: null }));
        this.autoConnectFields();
        this.reorderTargetFields();
      }
    },
    autoConnectFields() {
      this.localSourceFields.forEach((sourceField, index) => {
        const normalizedSourceField = sourceField.replace(/\s+/g, '').toLowerCase();
        this.localTargetFields.forEach(targetField => {
          const normalizedTargetField = targetField.name.replace(/\s+/g, '').toLowerCase();
          if (normalizedSourceField === normalizedTargetField) {
            this.mappings[sourceField] = targetField.name;
            targetField.order = index;
            console.log(`Auto-Connected Source Field: ${sourceField} (Index: ${index}), Target Field: ${targetField.name} (Order: ${targetField.order})`);
          }
        });
      });
      this.reorderTargetFields();
    },
  },
  mounted() {
    this.localSourceFields = [...this.sourceFields];
    this.localTargetFields = this.targetFields.map(field => ({ name: field, order: null }));
    this.autoConnectFields();
    this.reorderTargetFields();
  },
};
</script>

<style scoped>
.field-mapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-section {
  margin-bottom: 20px;
}

.columns {
  display: flex;
  justify-content: center;
  width: 50%;
}

.table-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}

.source-column, .target-column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.source-column .field-item, .target-column .field-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

.field-item {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
}

.target-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Right-align the target fields */
}

.normal-size-button {
  width: auto; /* Reset the width for the generate button */
}
</style>
