import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicBreadCrumb = _resolveComponent("DynamicBreadCrumb")!
  const _component_WorkOrderRequestForm = _resolveComponent("WorkOrderRequestForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DynamicBreadCrumb, { PageTitle: "Create Work Order Request" }),
    _createVNode(_component_WorkOrderRequestForm)
  ], 64))
}