<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="d-sm-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">Order Counts by Container</h5>
      </div>
      <div id="orderCountChartByCount" class="chart">
        <apexchart
          type="bar"
          height="374"
          :options="chartOptions"
          :series="chartData"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import axios from "axios";
import { ApexOptions } from "apexcharts";
import { BASE_HSUITE_URL } from "@/core/constant/env";

interface ContainerData {
  name: string;
  count: number;
  volume: number;
  orderLineCount: number;
  selected: boolean;
}

interface ChartData {
  name: string;
  data: number[];
}

export default defineComponent({
  name: "OrderCountAnalysisByCount",
  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chartData = ref<ChartData[]>([
      {
        name: "Order Count",
        data: [],
      },
    ]);

    const chartOptions = ref<ApexOptions>({
      chart: {
        height: 374,
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          const total = chartData.value[0].data.reduce((sum, value) => sum + value, 0);
          const percentage = ((val / total) * 100).toFixed(2);
          return `${percentage}%`;
        },
      },
      xaxis: {
        categories: [], // This will be updated dynamically
        type: "category",
        labels: {
          show: true,
          rotate: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Order Count",
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const container = containers.value[dataPointIndex];
          return `
            <div class="arrow_box">
              <span><strong>Container Name:</strong> ${container.name}</span><br>
              <span><strong>Order Count:</strong> ${container.count}</span><br>
              <span><strong>Order Line Count:</strong> ${container.orderLineCount}</span><br>
              <span><strong>Volume:</strong> ${container.volume}</span>
            </div>
          `;
        },
      },
    });

    const containers = ref<ContainerData[]>([]);

    const fetchData = async () => {
      if (!props.tableName) {
        console.warn("fetchData: tableName is not defined or is empty.");
        return;
      }

      try {
        console.log(`fetchData: Fetching data for tableName: ${props.tableName}`);
        const response = await axios.get(`${BASE_HSUITE_URL}/api/OOCAnalysis/GetPrecomputedOrderCounts`, {
          params: {
            tableName: props.tableName,
          },
        });

        if (response.data.status === "Success") {
          containers.value = response.data.orderCounts.map((item: any) => ({
            name: item.containerName,
            count: item.count,
            volume: item.volume,
            orderLineCount: item.orderLineCount,
            selected: true,
          }));

          updateChart();
        } else {
          console.warn("fetchData: API response status is not 'Success':", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const updateChart = () => {
      // Filter the containers that are selected
      const filteredContainers = containers.value.filter((container) => container.selected);

      // Sort the filtered containers alphabetically by name
      const sortedContainers = filteredContainers.sort((a, b) => a.name.localeCompare(b.name));

      // Update chart data and categories based on sorted containers
      chartData.value[0].data = sortedContainers.map((container) => container.count);
      chartOptions.value.xaxis!.categories = sortedContainers.map((container) => container.name);

      // Trigger the chart to re-render with updated options
      chartOptions.value = { ...chartOptions.value };
    };

    onMounted(() => {
      console.log('onMounted: Component mounted, triggering fetchData');
      fetchData();
    });

    watch(
      () => props.tableName,
      (newTableName) => {
        if (newTableName) {
          console.log('watch: tableName changed, triggering fetchData for new tableName:', newTableName);
          fetchData();
        }
      }
    );

    return {
      chartData,
      chartOptions,
      containers,
      updateChart,
    };
  },
});
</script>

<style scoped>
.form-check-inline {
  margin-bottom: 5px;
  margin-right: 10px;
}

.form-check-label {
  margin-left: 3px;
}

.toggle-controls {
  margin-top: 15px;
}

.small {
  font-size: 12px;
}

.arrow_box {
  padding: 10px;
  color: #fff;
  background: #333;
  border-radius: 4px;
  text-align: left;
}
</style>
