import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-4" }
const _hoisted_3 = { class: "col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_ProjectSummary = _resolveComponent("ProjectSummary")!
  const _component_DataStreamList = _resolveComponent("DataStreamList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Data Overview" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProjectSummary, { leadID: _ctx.leadID }, null, 8, ["leadID"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DataStreamList, { leadID: _ctx.leadID }, null, 8, ["leadID"])
      ])
    ])
  ]))
}