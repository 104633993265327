<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../assets/images/SEC_Group - Logo White.png" alt="logo-icon" />
        <span class="fw-bold ms-10"></span>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >
          <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
            <a
              href="#"
              class="accordion-button rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseElsa"
              aria-expanded="true"
              aria-controls="sidebarCollapseElsa"
            >
              <i class="flaticon-more-1"></i>
              <span class="title">ELSA</span>
            </a>
          <div
            id="sidebarCollapseElsa"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/projects-list" class="sidebar-sub-menu-link" v-if="currentPermissions?.includes('project.read')">
                    Projects
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/sku-data" class="sidebar-sub-menu-link">
                    SKU Data
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
          v-for="layoutView in layoutViews" :key="layoutView.layoutSectionId"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseTwentyThree"
            aria-expanded="true"
            aria-controls="sidebarCollapseTwentyThree"
          >
            <i class="flaticon-more-1"></i>
            <span class="title">{{layoutView.layoutSectionName}}</span>
          </a>
          <div
            id="sidebarCollapseTwentyThree"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <template v-for="layout in layoutView.layouts" :key="layout.layoutId">
                  <li class="sidebar-sub-menu-item">
                    <router-link v-if="layout.layoutType == 1" :to="{ name: layout.pageName, params: { id: layout.layoutId }  }" class="sidebar-sub-menu-link">
                      {{layout.displayName}}
                    </router-link>
                    <router-link v-else :to="{ name: layout.pageName }" class="sidebar-sub-menu-link">
                      {{layout.displayName}}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0">
            <a
              href="#"
              class="accordion-button rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseElsa"
              aria-expanded="true"
              aria-controls="sidebarCollapseElsa"
            >
              <i class="flaticon-more-1"></i>
              <span class="title">Settings</span>
            </a>
          <div
            id="sidebarCollapseElsa"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/users-list" class="sidebar-sub-menu-link" v-if="currentPermissions?.includes('user.read')">
                    Users
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                <router-link to="/roles" class="sidebar-sub-menu-link" v-if="currentPermissions?.includes('role.read')">
                  Roles
                </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item">
          <a @click="ProcessLogout" class="sidebar-nav-link d-block" style="cursor: pointer;">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import stateStore from "../../utils/store";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Logout } from '@/core/services/entities/authentication.service'
import breadcrumbMixin from '@/mixins/BreadcrumbMixin'

export default defineComponent({
  name: "MainSidebar",
  mixins: [breadcrumbMixin],
  setup() {
    const stateStoreInstance = stateStore;
    const store = useStore();
    const layoutViews = computed(() => store.state.auth.layouts);
    const router = useRouter();
    
    const ProcessLogout = () => 
    {
      Logout()
      router.push({ name: 'EcommercePage' });
    }

    return {
      layoutViews,
      stateStoreInstance,
      store,
      ProcessLogout
    };
  },
});
</script>