const USER_KEY = 'user_key'
const LAYOUTS_KEY = 'layouts_key'
const PERMISSIONS_KEY='permissions_key'
const ELISA_USER_KEY = 'elisa_user_key'

export const getToken = () => {
  const user = JSON.parse(window.localStorage.getItem(USER_KEY))
  const check = !!user
  return check ? user.token : null
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(USER_KEY))
}

export const saveUser = user => {
  window.localStorage.setItem(USER_KEY, JSON.stringify(user))
}

export const getLayouts = () => {
  return JSON.parse(window.localStorage.getItem(LAYOUTS_KEY))
}

export const saveLayouts = layouts => {
  window.localStorage.setItem(LAYOUTS_KEY, JSON.stringify(layouts))
}

export const getPermissions = () => {
  return JSON.parse(window.localStorage.getItem(PERMISSIONS_KEY))
}

export const savePermissions = permissions => {
  window.localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions))
}

export const destroyToken = () => {
  window.localStorage.removeItem(USER_KEY)
  window.localStorage.removeItem(LAYOUTS_KEY)
  window.localStorage.removeItem(PERMISSIONS_KEY)
  window.localStorage.removeItem(ELISA_USER_KEY)
}

export const getElisaUser = () => {
  return JSON.parse(window.localStorage.getItem(ELISA_USER_KEY))
}

export const saveElisaUser = elisaUser => {
  window.localStorage.setItem(ELISA_USER_KEY, JSON.stringify(elisaUser))
}

export default { getToken, getUser, saveUser, destroyToken, getLayouts, saveLayouts,getPermissions, savePermissions, getElisaUser, saveElisaUser}
